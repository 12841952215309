import { useCallback, useRef, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import { useAuth } from './useAuth';
import { SessionStorage, sleep } from '../ui/utils';

async function waitAsyncCallback(asyncCallback, wait, mounted) {
  const waitTime = wait ? wait : 500;
  await sleep(waitTime);
  if (mounted && mounted.current) {
    return await asyncCallback();
  }
}

export function useApiService(config) {
  const {
    token = null,
    baseUri = '',
    auth: authProp = null,
    cache: cacheProp = false,
    cacheValidFor: cacheValidForProp = 2000,
    asyncStorageKey = '@understory:api:cache',
  } = config;
  const mounted = useRef(true);
  useEffect(() => {
    return () => (mounted.current = false);
  }, []);

  const authContext = useAuth();
  const auth = authProp ? authProp : authContext ? authContext : {};
  const { getUser, renewSession } = auth;
  const apiCalls = useRef(new Map());

  const callApi = useCallback(
    async (requestConfig, waitCount = 0) => {
      const {
        path: p,
        body,
        method = 'get',
        errorMessage,
        authenticated: needsAuth = true,
        validateResponse: v,
        baseUri: b,
        forceCache = false, // <- use this to cache a post response for example
        cache: c = undefined,
        cacheKey: cKey = undefined,
        asyncStorageKey: aSKey = undefined,
        cacheValidFor: cValidFor = undefined,
        exclusive = false,
        maxWaitCount = 5,
      } = requestConfig;

      let response = null;
      const path = `${b ? b : baseUri}${p}`;
      const isRunning = apiCalls.current.get(path) === true ? true : false;
      if (!isRunning) {
        apiCalls.current.set(path, true);
      }
      const cache = forceCache === true ? true : method === 'get' ? (c !== undefined ? c : cacheProp) : false;
      const cacheKey = cache ? `${aSKey !== undefined ? aSKey : asyncStorageKey}:${cKey !== undefined ? cKey : path}` : null;
      const cacheValidFor = cValidFor !== undefined ? cValidFor : cacheValidForProp;
      const validate = v ? v : validateResponse;
      if (cache && cacheKey) {
        try {
          let lastResponse = await SessionStorage.getItem(cacheKey);
          if (lastResponse) {
            const { data, timestamp } = JSON.parse(lastResponse);
            if (data && moment.now() - timestamp < cacheValidFor) {
              apiCalls.current.set(path, false);
              return data;
            }
          }
        } catch (cacheError) {
          if (process.env.NODE_ENV !== 'production') {
            console.warn('cacheError for key: ', cacheKey);
            console.warn(cacheError);
          }
        }
      }
      if (isRunning && exclusive && waitCount < maxWaitCount) {
        return await waitAsyncCallback(
          async () => {
            return await callApi(requestConfig, typeof waitCount === 'number' ? waitCount + 1 : 1);
          },
          cacheValidFor / 2,
          mounted
        );
      }

      try {
        if (needsAuth && token) {
          response = await request({ token, path, body, method });
        } else if (needsAuth && getUser && renewSession) {
          response = await getUser().then((user) => {
            if (user && user.access_token && !user.expired) {
              return request({ token: user.access_token, path, body, method }).catch((error) => {
                if (error.response && error.response.status === 401 && user.refresh_token) {
                  return renewSession().then((renewedUser) => {
                    return request({ token: renewedUser.access_token, path, body, method });
                  });
                }
                throw error.response; // handle in client
              });
            } else if (user && user.refresh_token) {
              return renewSession().then((renewedUser) => {
                return request({ token: renewedUser.access_token, path, body, method });
              });
            } else {
              throw new Error('User is not logged in.');
            }
          });
        } else {
          response = await request({ path, body, method });
        }
        validate(response, errorMessage);
        if (cache && cacheKey) {
          try {
            await SessionStorage.setItem(cacheKey, JSON.stringify({ data: response.data, timestamp: moment.now() }));
          } catch (cacheError) {
            try {
              if (cacheError.name === 'QuotaExceededError' && asyncStorageKey) {
                const storageKeys = await SessionStorage.getAllKeys();
                if (storageKeys && Array.isArray(storageKeys)) {
                  const apiStorageKeys = storageKeys.filter((k) => k.startsWith(asyncStorageKey));
                  if (apiStorageKeys.length) {
                    await SessionStorage.multiRemove(apiStorageKeys);
                    await SessionStorage.setItem(cacheKey, JSON.stringify({ data: response.data, timestamp: moment.now() }));
                  }
                }
              }
            } catch (cacheError2) {
              if (process.env.NODE_ENV !== 'production') {
                console.warn('cacheError when storing for key: ', cacheKey);
                console.warn(cacheError);
                console.warn(cacheError2);
              }
            }
          }
        }
        apiCalls.current.set(path, false);
        const isDownloadRequest = response.config.url.includes('/download');
        const isSupersedeRequest = response.config.url.includes('/superseders');
        if (isDownloadRequest || isSupersedeRequest) return response;
        else return response.data;
      } catch (caughtError) {
        apiCalls.current.set(path, false);
        throw caughtError;
      }
    },
    [baseUri, token, getUser, renewSession, asyncStorageKey, cacheProp, cacheValidForProp]
  );

  const get = useCallback(
    (requestConfig) => {
      return callApi({ ...requestConfig, method: 'get' });
    },
    [callApi]
  );

  const put = useCallback(
    (requestConfig) => {
      return callApi({ ...requestConfig, method: 'put' });
    },
    [callApi]
  );

  const post = useCallback(
    (requestConfig) => {
      return callApi({ ...requestConfig, method: 'post' });
    },
    [callApi]
  );

  const _delete = useCallback(
    (requestConfig) => {
      return callApi({ ...requestConfig, method: 'delete', body: null });
    },
    [callApi]
  );

  const postForm = useCallback(
    ({ formData, ...requestConfig }) => {
      return callApi({ ...requestConfig, method: 'post_form', body: formData });
    },
    [callApi]
  );

  const putForm = useCallback(
    ({ formData, ...requestConfig }) => {
      return callApi({ ...requestConfig, method: 'put_form', body: formData });
    },
    [callApi]
  );

  return {
    get,
    put,
    post,
    delete: _delete,
    postForm,
    putForm,
    apiCalls,
    cancel: cancelRequests,
    isCancel,
  };
}

const source = axios.CancelToken.source();

export const cancelRequests = (message = 'Operation was canceled by the user.') => {
  source.cancel(message);
};

export const isCancel = (e) => {
  if (!e) {
    return false;
  }
  return axios.isCancel(e);
};

function request({ path, body, method: httpMethod, token }) {
  const headers = { Accept: 'application/json' };

  if (token) {
    headers.Authorization = 'Bearer ' + token;
  }

  let method = httpMethod;

  if (method === 'post_form') {
    headers['Content-Type'] = 'multipart/form-data';
    headers['Content-Disposition'] = `form-data; name="file"; filename="${body.get('files').name}"`;
    method = 'post';
  } else if (method === 'put_form') {
    headers['Content-Type'] = 'multipart/form-data';
    headers['Content-Disposition'] = `form-data; name="file"; filename="${body.get('files').name}"`;
    method = 'put';
  }

  return axios({
    method,
    url: path,
    data: body,
    headers: headers,
    cancelToken: source.token,
  });
}

function validateResponse(response, errorMessage) {
  if (!response) {
    throw new Error('Error. No response from server.');
  }
  if (response.status === 400) {
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    throw new Error(errorMessage);
  }
  if (response.status === 403) {
    throw new Error("You don't have permission to execute that action.");
  }
  if (response.status === 401) {
    throw new Error("Your user wasn't recognized or logged in. Try logging out and back in.");
  }
}
