import React, { useState, useEffect } from 'react';
import { Dialog, LayoutBox } from '../../components';
import { Autocomplete, Box, Button, DatePicker, Spacing, TextField } from '../../ui';
import { RowLayout } from '../SubmitApplicationPage/components';
import moment from 'moment-timezone';
import { useEventCallback } from '../../ui/hooks';
import { isNull } from '../../ui/utils';
import { useApi } from '../../hooks';

const BindApplicationDialog = (props) => {
  const { applicationId, open, packages, onClose, onSuccess, ...rest } = props;

  const [policyNumber, setPolicyNumber] = useState('');
  const [packageId, setPackageId] = useState();
  const [netPremium, setNetPremium] = useState(0);
  const [effectiveEndDate, setEffectiveEndDate] = useState(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const { bindApplication } = useApi();
  const [loading, setLoading] = useState(false);

  const startDate = new moment();
  startDate.add(90, 'days');
  startDate.startOf('month');
  const [d, setD] = useState(effectiveEndDate ? moment(effectiveEndDate) : startDate);
  useEffect(() => {
    if (d) {
      setEffectiveEndDate(d.valueOf());
    }
  }, [d, setEffectiveEndDate]);

  const resetData = useEventCallback(() => {
    setErrors({});
    setPolicyNumber('');
    setNetPremium(0);
    setEffectiveEndDate(startDate);
    setPackageId('');
  });

  useEffect(() => {
    if (open) {
      resetData();
    }
  }, [open, resetData]);

  const validateDetails = () => {
    const errors = { validationError: true };
    let valid = true;
    if (!policyNumber.length) {
      errors.policyNumber = 'Required';
      valid = false;
    }
    if (netPremium === 0) {
      errors.netPremium = 'Required';
      valid = false;
    }
    if (!packageId.length) {
      errors.packageId = 'Select a package';
      valid = false;
    }

    if (!valid) {
      throw errors;
    }

    return true;
  };

  const handleOnSubmit = async () => {
    setLoading(true);
    try {
      setErrors({});
      validateDetails();
      const payload = {
        sourcePolicyNumber: policyNumber,
        netPremium,
        packageId,
        effectiveUntil: moment(effectiveEndDate).format('YYYY-MM-DD'),
      };
      const generatedPolicy = await bindApplication(applicationId, payload);
      if (generatedPolicy) {
        onSuccess(generatedPolicy);
      }
    } catch (e) {
      setErrors(e);
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      heading={'Add Policy Details'}
      styles={{
        dialogBox: {
          marginLeft: ({ theme }) => theme.layout.centeredOverlay.marginLeft,
          width: ({ theme }) => theme.breakpoints({ sm: 640, md: '$mainWidth' }),
          maxWidth: '100%',
          padX: ({ theme }) => theme.breakpoints({ xs: '$2', sm: '$4' }),
        },
      }}
      onClose={onClose}
      open={open}
      {...rest}
    >
      <LayoutBox>
        <Box flex={1} alignSelf="stretch">
          <TextField
            label="Policy Number"
            value={policyNumber}
            onChangeValue={setPolicyNumber}
            helperText={errors.policyNumber ? errors.policyNumber : undefined}
            error={errors.policyNumber}
            hideHelperText={isNull(errors.policyNumber)}
          />
          <RowLayout>
            <TextField
              label="Net Premium"
              value={netPremium}
              precision={2}
              type="number"
              onChangeValue={setNetPremium}
              flex="1"
              helperText={errors.netPremium ? errors.netPremium : undefined}
              error={errors.netPremium}
              hideHelperText={isNull(errors.netPremium)}
            />
          </RowLayout>
          <RowLayout>
            <TextField
              label="Effective End Date"
              value={moment(effectiveEndDate).format('MM-DD-YYYY')}
              onPress={(date) => {
                setDatePickerOpen(true);
              }}
              flex="1"
            />
          </RowLayout>
          <Dialog open={datePickerOpen} onClose={() => setDatePickerOpen(false)}>
            <DatePicker
              date={d}
              onChange={(date) => {
                setD(date);
                setDatePickerOpen(false);
              }}
            />
          </Dialog>
          <RowLayout>
            <Autocomplete
              options={packages}
              autoComplete
              autoHighlight
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  const packageOption = packages.find((p) => p.id === option);
                  if (packageOption) {
                    return packageOption.name;
                  }
                } else if (option) {
                  return option.name;
                } else {
                  return '';
                }
              }}
              isOptionEqualToValue={(option, value) => option.id === value || option === value}
              value={packageId}
              onChangeValue={(v) => setPackageId(v && v.id ? v.id : null)}
              renderInput={(iProps) => (
                <TextField
                  {...iProps}
                  label="Package"
                  placeholder="-- Select a package --"
                  filled
                  helperText={errors.packageId ? errors.packageId : undefined}
                  error={errors.packageId}
                  hideHelperText={isNull(errors.packageId)}
                />
              )}
              flex="1"
            />
          </RowLayout>
          <Spacing vertical={3} />
        </Box>
        <Button label={'Submit Changes'} alignSelf="flex-end" onPress={handleOnSubmit} loading={loading} disabled={loading} />
      </LayoutBox>
    </Dialog>
  );
};

export { BindApplicationDialog };
