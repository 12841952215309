import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, GridModal, Text } from '../../../ui';
import { usePolicyPageContext } from '../context/PolicyPageContext';
import { useApi, useAsyncSearch, useNavigate } from '../../../hooks';
import moment from 'moment-timezone';
import { CustomDatePicker, LayoutBox } from '../../../components';
import { formatMoney } from '../../../utils';
import { isEmpty } from '../../../ui/utils';
import { hasMixedDataTypes } from '../../../utils/hasMixedDataType';
import CombineLocationModal from './CombineLocationModal';

const CreateRetroMCEModal = ({ showModal, setShowModal, policyId, getPayload, createMCE }) => {
  const navigate = useNavigate();
  const { setCurrentTab = () => {}, setMCEsData } = usePolicyPageContext();
  const [editEffectiveAt, setEditEffectiveAt] = useState(false);
  const [editEffectiveUntil, setEditEffectiveUntil] = useState(false);
  const payload = getPayload();
  const [effectiveAt, setEffectiveAt] = useState(moment.utc(payload.effectiveAt));
  const [effectiveUntil, setEffectiveUntil] = useState(moment.utc(payload.effectiveUntil));
  const [inProgress, setInProgress] = useState(false);

  const handleOnSubmit = async () => {
    setInProgress(true);

    try {
      const updatedPayload = {
        ...payload,
        effectiveAt: moment(effectiveAt).format('YYYY-MM-DD'),
        effectiveUntil: moment(effectiveUntil).format('YYYY-MM-DD'),
      };

      console.log('updatedPayload', updatedPayload);
      const response = await createMCE(policyId, updatedPayload);
      if (!response || response.length === 0) {
        alert('Could not create retrospective MCE. Please ask admin.');
      } else {
        setMCEsData((prevData) => [...prevData, ...response]);

        if (response.length === 1) {
          const mceId = response[0].id;
          navigate.to(`/policies/${policyId}/mces/${mceId}`);
        } else if (response.length > 1) {
          alert('Created two retrospective MCEs surrounding location change MCE.');
          setCurrentTab('MCES');
        } else {
          console.error('Unexpected response structure: ', response);
        }
      }
    } catch (error) {
      console.error('Failed to create retrospective MCE:', error);
      alert('Unable to create the MCE. Please try again later.');
    } finally {
      setShowModal(false);
      setInProgress(false);
    }
  };

  return (
    <GridModal
      title={'Create Retrospective MCE'}
      buttonText={'Create'}
      handlePress={handleOnSubmit}
      open={showModal}
      onClose={() => setShowModal(false)}
      width="480px"
      capitalizeTitle={true}
      loading={inProgress}
      disabled={inProgress}
    >
      <LayoutBox gap={16} padding="16px">
        <LayoutBox row alignItems="flex-start" padding="8px" paddingLeft="0px" gap={8} alignSelf="stretch" width="100%">
          <Box style={{ color: '#637381', fontSize: 16, fontWeight: 500, alignItems: 'flex-start' }} width="40%" marginY="auto">
            <Text style={{ color: '#212B36', fontSize: 16, fontWeight: 500 }}>Effective At:</Text>
          </Box>
          <Box style={{ color: '#637381', fontSize: 16, fontWeight: 500 }} width="50%">
            <CustomDatePicker
              label="Date"
              placeholder={'MM/DD/YYYY'}
              shouldShowPicker={editEffectiveAt}
              setShouldShowPicker={setEditEffectiveAt}
              setDate={setEffectiveAt}
              date={effectiveAt}
            />
          </Box>
        </LayoutBox>
        <LayoutBox row alignItems="flex-start" padding="8px" paddingLeft="0px" gap={8} alignSelf="stretch" width="100%" zIndex={-3}>
          <Box style={{ color: '#637381', fontSize: 16, fontWeight: 500, alignItems: 'flex-start' }} width="40%" marginY="auto">
            <Text style={{ color: '#212B36', fontSize: 16, fontWeight: 500 }}>Effective Until:</Text>
          </Box>
          <Box style={{ color: '#637381', fontSize: 16, fontWeight: 500 }} width="50%">
            <CustomDatePicker
              label="Date"
              placeholder={'MM/DD/YYYY'}
              shouldShowPicker={editEffectiveUntil}
              setShouldShowPicker={setEditEffectiveUntil}
              setDate={setEffectiveUntil}
              date={effectiveUntil}
            />
          </Box>
        </LayoutBox>
      </LayoutBox>
    </GridModal>
  );
};

export const MonthlyReporting = React.forwardRef((props) => {
  const { policy = [], setGridReference } = props;
  const { createMCE } = useApi();
  const { editMonthlyReporting, setMonthlyReportingData, monthlyReportingData, MCEsData, combinedMode, groups } = usePolicyPageContext();
  const [rowData, setRowData] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showCombineLocationsModal, setShowCombineLocationsModal] = useState(false);
  let updateTimeout = null;

  const handleCheckboxChange = (e, columnIndex) => {
    if (e.target.checked) {
      setSelectedColumns((prevColumns) => [...prevColumns, columnIndex]);
    } else {
      setSelectedColumns((prevColumns) => prevColumns.filter((index) => index !== columnIndex));
    }
  };
  function findEmptyMonths(data, months) {
    return months.filter((_, monthIndex) => data.every((location) => location.values[monthIndex] === null));
  }

  const formatPayload = useCallback(
    (columnIndex) => {
      const payload = {
        months: [monthlyReportingData?.months[columnIndex]],
      };

      return payload;
    },
    [monthlyReportingData]
  );

  const sumValues = (values) => {
    return values.reduce((sum, value) => {
      const numericValue = typeof value === 'string' && !isNaN(parseFloat(value)) ? parseFloat(value) : value;
      if (typeof numericValue === 'number') {
        return sum + numericValue;
      }
      return sum;
    }, 0);
  };
  const emptyMonths = findEmptyMonths(monthlyReportingData?.locations || [], monthlyReportingData?.months || []);
  const getCellStyle = (params, date) => {
    if (emptyMonths.includes(date)) return null;

    if (params.data.type === 'monthlyInventory') {
      let monthlyValues = [];
      const extractDateFromKey = (key) => key.split('_')[1];
      Object.entries(params.data).forEach(([key, value]) => {
        const dateMatch = key.includes(`monthlyInventory`);
        if (dateMatch && !emptyMonths.includes(extractDateFromKey(key))) {
          monthlyValues.push(value);
        }
      });

      const hasMixedTypesValues = hasMixedDataTypes(monthlyValues);
      if (hasMixedTypesValues) {
        return { background: '#FFB3B3' };
      }
    }

    return null;
  };

  const getPayload = useCallback(() => {
    const payloads = selectedColumns.map(formatPayload);

    const combinedPayload = payloads.reduce((acc, payload) => {
      Object.entries(payload).forEach(([key, value]) => {
        acc[key] = (acc[key] || []).concat(value);
      });
      return acc;
    }, {});

    const sortedMonths = combinedPayload?.months?.sort((a, b) => new Date(a) - new Date(b));
    if (!sortedMonths || sortedMonths.length === 0) {
      return {};
    }
    const getFirstOfMonth = (dateString) => dateString.slice(0, 7) + '-01';
    const finalPayload = {
      type: 'retrospective',
      effectiveAt: getFirstOfMonth(sortedMonths[0]),
      effectiveUntil: sortedMonths[sortedMonths.length - 1],
    };

    return finalPayload;
  }, [selectedColumns, formatPayload]);

  const getRandomColor = (() => {
    const colors = {};
    return (id) => {
      if (!colors[id]) {
        colors[id] = `hsl(${Math.random() * 360}, 70%, 50%)`;
      }
      return colors[id];
    };
  })();

  const locationToParentMap = new Map();
  groups.forEach((group) => {
    group.children.forEach((child) => {
      locationToParentMap.set(child.locationId, group.parentId);
    });
  });

  const parentIdColorMap = new Map();
  groups.forEach((group) => {
    parentIdColorMap.set(group.parentId, getRandomColor(group.parentId));
  });

  const createColumns = () => {
    const baseColumns = [
      {
        headerName: 'Declared Locations',
        children: [
          {
            headerName: 'RiskId',
            field: 'riskId',
            minWidth: 100,
            cellRenderer: (params) => {
              const parentId = locationToParentMap.get(params.data?.id);
              const color = parentId ? parentIdColorMap.get(parentId) : parentIdColorMap.get(params.data?.id);
              if (color && combinedMode) {
                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {params.value}
                    <span
                      style={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: color,
                        marginLeft: '8px',
                      }}
                    ></span>
                  </div>
                );
              } else return params.value !== undefined ? params.value : '';
            },
          },
          {
            headerName: 'Name',
            field: 'name',
            minWidth: 200,
            cellRenderer: (params) => {
              if (params.data.name === 'Combine Locations') {
                return (
                  <LayoutBox>
                    <Box border={'1px solid #707E8A'} borderRadius={8} marginTop="$2" marginBottom="$2">
                      <Button
                        color={'$primary'}
                        variant="text"
                        label={'Combine Locations'}
                        paddingX={8}
                        paddingY={4}
                        onPress={() => {
                          setShowCombineLocationsModal(true);
                        }}
                        style={{
                          cursor: !combinedMode ? 'not-allowed' : 'pointer',
                          opacity: !combinedMode ? 0.6 : 1,
                        }}
                        minHeight={50}
                        disabled={!combinedMode}
                      />
                    </Box>
                  </LayoutBox>
                );
              } else return params.value !== undefined ? params.value : ''; // Avoid blank cells
            },
            cellStyle: (params) => {
              if (params.data.name === 'Combine Locations') {
                return { textAlign: 'center' };
              }
              return null;
            },
          },
          {
            headerName: 'Address',
            field: 'address',
            minWidth: 250,
            cellClassRules: {
              'blue-background': (params) => {
                return [
                  '% of Bound',
                  'Total',
                  'Date Reported',
                  'Preparer',
                  'File Type',
                  'Understory Checked',
                  'Understory Signed Off',
                  'Include in MCE',
                ].includes(params.data.address);
              },
            },
            cellRenderer: (params) => {
              if (params.data.address === 'Create MCE') {
                return (
                  <LayoutBox>
                    <Box border={'1px solid #707E8A'} borderRadius={8} marginTop="$2" marginBottom="$2">
                      <Button
                        color={'$primary'}
                        variant="text"
                        label={'Create Retroactive MCE'}
                        paddingX={8}
                        paddingY={4}
                        onPress={() => {
                          if (!selectedColumns || selectedColumns.length === 0) {
                            alert('Please select month.');
                          } else {
                            setShowModal(true);
                          }
                        }}
                        minHeight={50}
                      />
                    </Box>
                  </LayoutBox>
                );
              }
              return params.value !== undefined ? params.value : ''; // Avoid blank cells
            },
            cellStyle: (params) => {
              if (params.data.address === 'Create MCE') {
                return { textAlign: 'center' };
              }
              return null;
            },
          },
          {
            headerName: 'Bound TIV',
            field: 'boundTiv',
            minWidth: 150,
            valueFormatter: (params) => {
              if (!params.data.formatter || typeof params.value === 'string') return;
              if (params.data.address === '% of Bound') {
                return params.value ? `${params.value.toFixed(2)}%` : '';
              }
              return !isEmpty(params.data) &&
                params.data.address !== 'Create MCE' &&
                params.data.name !== 'Combine Locations' &&
                params.value !== null
                ? `${formatMoney(params.value, { prefix: '$', withDecimals: true })}`
                : '';
            },

            cellStyle: (params) => {
              if (params.data.type === 'label' || typeof params.data.boundTiv === 'string') {
                return {
                  border: '1px solid black',
                  fontWeight: 'bold',
                };
              } else if (params.data.address === 'Total') {
                return {
                  fontWeight: 'bold',
                };
              }
              return null;
            },
          },
        ],
      },
    ];

    const monthlyColumns =
      monthlyReportingData && monthlyReportingData.months
        ? monthlyReportingData.months.map((date, index) => {
            return {
              headerName: date,
              children: [
                {
                  headerName: 'Declared Inventory Value',
                  field: `monthlyInventory_${date}_${index}`,
                  minWidth: 150,
                  valueFormatter: (params) => {
                    if (!params.data.formatter || typeof params.value === 'string') return;
                    if (params.data.address === '% of Bound') {
                      return params.value ? `${params.value.toFixed(2)}%` : '';
                    }
                    return !isEmpty(params.data) &&
                      params.data.address !== 'Create MCE' &&
                      params.data.name !== 'Combine Locations' &&
                      params.value !== null
                      ? `${formatMoney(params.value, { prefix: '$', withDecimals: true })}`
                      : '';
                  },

                  cellRenderer: (params) => {
                    const colId = params.column.getColId(); // Get the column index
                    const columnIndex = parseInt(colId.match(/(\d+)$/)[1], 10);
                    if (params.data.address === 'Include in MCE') {
                      return (
                        <input
                          type="checkbox"
                          style={{
                            width: '20px',
                            height: '20px ',
                            cursor: `${monthlyReportingData.uwSignedOff[Number(columnIndex)] || !editMonthlyReporting ? 'pointer' : 'not-allowed'}`,
                          }}
                          onChange={(e) => handleCheckboxChange(e, columnIndex)}
                          disabled={!monthlyReportingData.uwSignedOff[Number(columnIndex)] || !editMonthlyReporting}
                          checked={selectedColumns.includes(Number(columnIndex))} // Check if the extracted index is selected
                        />
                      );
                    } else if (typeof params.value === 'string') return params.value;
                    else if (params.data.address === '% of Bound') {
                      return params.value ? `${params.value.toFixed(2)}%` : '';
                    }
                    return !isEmpty(params.data) &&
                      params.data.address !== 'Create MCE' &&
                      params.data.name !== 'Combine Locations' &&
                      params.value !== null
                      ? `${formatMoney(params.value, { prefix: '$', withDecimals: true })}`
                      : '';
                  },
                  cellEditorSelector: (params) => {
                    if (params.data.address === 'File Type') {
                      return {
                        component: 'agRichSelectCellEditor',
                        params: {
                          values: ['File Type', 'Spreadsheet', 'Document', 'PDF', 'Image'],
                        },
                      };
                    } else if (
                      ['Preparer', 'Understory Checked', 'Understory Signed Off'].includes(params.data.address) ||
                      params.data.type === 'monthlyInventory'
                    ) {
                      return { component: 'agTextCellEditor' };
                    } else if (params.data.address === 'Date Reported') {
                      return {
                        component: 'agDateCellEditor',
                        params: {
                          dateFormat: 'YYYY-MM-DD',
                        },
                      };
                    }
                    return null;
                  },
                  editable: (params) => {
                    return (
                      (params.data.type === 'monthlyInventory' ||
                        ['File Type', 'Date Reported', 'Preparer', 'Understory Checked', 'Understory Signed Off'].includes(params.data.address)) &&
                      editMonthlyReporting
                    );
                  },
                  cellClassRules: {
                    'ag-cell-disable': (params) => {
                      if (params.data.type !== 'monthlyInventory' || getCellStyle(params, date)) return false;

                      const fieldName = params.colDef.field;
                      if (!fieldName) return false;

                      const cellMonthKey = fieldName.split('_')[1].slice(0, 7);

                      const addedMonthKey = params.data.added ? params.data.added.slice(0, 7) : null;
                      const deletedMonthKey = params.data.deleted ? params.data.deleted.slice(0, 7) : null;

                      if (!addedMonthKey && !deletedMonthKey) return false;

                      if (deletedMonthKey) {
                        return cellMonthKey > deletedMonthKey;
                      }

                      if (addedMonthKey) {
                        return cellMonthKey < addedMonthKey;
                      }

                      return false;
                    },
                    'error-cell': (params) => {
                      const cellStyle = getCellStyle(params, date);
                      return cellStyle;
                    },
                    'ag-cell-editable': (params) => {
                      const cellStyle = getCellStyle(params, date);
                      if (cellStyle !== null) {
                        return false;
                      }
                      return (
                        !getCellStyle(params, date) &&
                        ((editMonthlyReporting && params.data.type === 'monthlyInventory') ||
                          (['File Type', 'Date Reported', 'Preparer', 'Understory Checked', 'Understory Signed Off', 'Include in MCE'].includes(
                            params.data.address
                          ) &&
                            editMonthlyReporting))
                      );
                    },
                  },

                  valueSetter: (params) => {
                    let value = params.newValue;
                    const colId = params.colDef.field;
                    const valueIndex = parseInt(colId.match(/(\d+)$/)[1], 10);
                    let updatedData = { ...monthlyReportingData };
                    if (params.data.type === 'dateReported') {
                      let dateValue;
                      if (typeof params.newValue === 'string') {
                        dateValue = new Date(params.newValue);
                      } else if (params.newValue instanceof Date) {
                        dateValue = params.newValue;
                      } else {
                        return false;
                      }

                      if (!isNaN(dateValue.getTime())) {
                        const year = dateValue.getFullYear();
                        const month = String(dateValue.getMonth() + 1).padStart(2, '0');
                        const day = String(dateValue.getDate()).padStart(2, '0');
                        updatedData[params.data.type][valueIndex] = `${year}-${month}-${day}`;
                        params.data[`monthlyInventory_${valueIndex}`] = `${year}-${month}-${day}`;
                      }
                    } else if (params.data.type !== 'monthlyInventory') {
                      updatedData[params.data.type][valueIndex] = value;
                      params.data[`monthlyInventory_${valueIndex}`] = value;
                    } else {
                      value = isNaN(parseInt(value)) || value === null ? value : parseInt(value); // Ensure value is an integer
                      const riskId = params.data.riskId;
                      const location = updatedData.locations.find((loc) => loc.riskId === riskId);
                      if (location) {
                        location.values[valueIndex] = value === '' ? null : value;
                        params.data[`monthlyInventory_${valueIndex}`] = value === '' ? null : value;
                      }
                    }
                    if (updateTimeout) clearTimeout(updateTimeout);

                    // Set a timeout to update the context after a delay
                    updateTimeout = setTimeout(() => {
                      setMonthlyReportingData(updatedData); // Update context with all changes
                      updateTimeout = null; // Clear timeout reference
                    }, 100);
                    // Return true to indicate the value was set successfully
                    return true;
                  },

                  cellStyle: (params) => {
                    if (
                      params.data.type === 'label' ||
                      ['Declared Inventory', 'Bound Inventory', 'Difference', 'Rate', 'Premium Due'].includes(params.data.boundTiv) ||
                      typeof params.data.boundTiv === 'string'
                    ) {
                      return {
                        border: '1px solid black',
                        fontWeight: 'bold',
                      };
                    } else if (params.data.address === 'Total') {
                      return {
                        fontWeight: 'bold',
                      };
                    }
                    return null;
                  },
                  cellDataType: 'string',
                },
              ],
            };
          })
        : [];

    return [...baseColumns, ...monthlyColumns];
  };

  const mapRowData = useCallback(() => {
    if (!monthlyReportingData || !monthlyReportingData.locations || !monthlyReportingData.months) {
      return [];
    }
    const executedMCEs = MCEsData.filter((mce) => mce.type === 'locationChange' && mce.status === 'executed');
    executedMCEs.sort((a, b) => new Date(b.effectiveAt) - new Date(a.effectiveAt));
    const mostRecentExecutedMCE = executedMCEs[0];
    const locations = mostRecentExecutedMCE?.locations?.length > 0 ? mostRecentExecutedMCE?.locations : policy?.locations;
    const baseRowData = locations
      ?.map((location) => {
        const matchingLocation = monthlyReportingData.locations.find((dummyLoc) => dummyLoc.riskId === location.riskId);
        const monthlyValues = matchingLocation
          ? monthlyReportingData.months.reduce((acc, date, index) => {
              acc[`monthlyInventory_${date}_${index}`] =
                matchingLocation.values?.[index] === null || isNaN(parseInt(matchingLocation.values?.[index]))
                  ? matchingLocation.values?.[index]
                  : parseInt(matchingLocation.values?.[index]);
              return acc;
            }, {})
          : {};

        return {
          name: location.name,
          address: location.address,
          id: location.id,
          formatter: true,
          type: 'monthlyInventory',
          riskId: location.riskId,
          boundTiv: location.coveredValue,
          deleted: location?.deleted || '',
          added: location?.added || '',
          ...monthlyValues,
        };
      })
      .sort((locA, locB) => {
        const riskIdA = locA.riskId || Number.MAX_SAFE_INTEGER;
        const riskIdB = locB.riskId || Number.MAX_SAFE_INTEGER;

        return riskIdA - riskIdB;
      });
    const totalRow = {
      address: 'Total',
      formatter: true,
      boundTiv: sumValues(baseRowData?.map((row) => row.boundTiv)),
      ...monthlyReportingData.months.reduce((acc, date, index) => {
        acc[`monthlyInventory_${date}_${index}`] = sumValues(baseRowData.map((row) => row[`monthlyInventory_${date}_${index}`] || 0));
        return acc;
      }, {}),
    };

    const percentageRow = {
      address: '% of Bound',
      formatter: true,
      boundTiv: totalRow.boundTiv ? (totalRow.boundTiv / totalRow.boundTiv) * 100 : 0,
      ...monthlyReportingData.months.reduce((acc, date, index) => {
        acc[`monthlyInventory_${date}_${index}`] = totalRow.boundTiv
          ? ((totalRow[`monthlyInventory_${date}_${index}`] || 0) / totalRow.boundTiv) * 100
          : 0;
        return acc;
      }, {}),
    };

    const checkBoxRow = {
      address: 'Include in MCE',
      formatter: false,
      ...monthlyReportingData.months.reduce((acc, date, index) => {
        acc[`monthlyInventory_${date}_${index}`] = false;
        return acc;
      }, {}),
    };

    const additionalRows = [
      {
        address: 'Date Reported',
        boundTiv: null,
        formatter: false,
        type: 'dateReported',
        ...monthlyReportingData.months.reduce((acc, date, index) => {
          acc[`monthlyInventory_${date}_${index}`] = monthlyReportingData.dateReported?.[index] || null; // Default to null if undefined
          return acc;
        }, {}),
      },
      {
        address: 'Preparer',
        boundTiv: null,
        type: 'preparer',
        formatter: false,
        ...monthlyReportingData.months.reduce((acc, date, index) => {
          acc[`monthlyInventory_${date}_${index}`] = monthlyReportingData.preparer?.[index] || null;
          return acc;
        }, {}),
      },
      {
        address: 'File Type',
        boundTiv: null,
        formatter: false,
        type: 'fileType',
        ...monthlyReportingData.months.reduce((acc, date, index) => {
          acc[`monthlyInventory_${date}_${index}`] = monthlyReportingData.fileType?.[index] || null;
          return acc;
        }, {}),
      },
      {
        address: 'Create MCE',
        name: 'Combine Locations',
      },
      {},
      {
        address: 'Understory Checked',
        boundTiv: null,
        formatter: false,
        type: 'uwChecked',
        ...monthlyReportingData.months.reduce((acc, date, index) => {
          acc[`monthlyInventory_${date}_${index}`] = monthlyReportingData.uwChecked?.[index] || null;
          return acc;
        }, {}),
      },
      {
        address: 'Understory Signed Off',
        boundTiv: null,
        formatter: false,
        type: 'uwSignedOff',
        ...monthlyReportingData.months.reduce((acc, date, index) => {
          acc[`monthlyInventory_${date}_${index}`] = monthlyReportingData.uwSignedOff?.[index] || null;
          return acc;
        }, {}),
      },
    ];

    const monthLabelRow = {
      type: 'label',
      ...monthlyReportingData.months.reduce((acc, date, index) => {
        const monthName = new Date(date).toLocaleString('default', { month: 'long' });
        acc[`monthlyInventory_${date}_${index}`] = monthName;
        return acc;
      }, {}),
      cellStyle: { border: '1px solid black' },
    };

    const summaryRows = [
      {
        boundTiv: 'Declared Inventory',
        formatter: true,
        ...monthlyReportingData.months.reduce((acc, date, index) => {
          acc[`monthlyInventory_${date}_${index}`] = monthlyReportingData.declaredInventory?.[index] || null; // Default to null if undefined
          return acc;
        }, {}),
      },
      {
        boundTiv: 'Bound Inventory',
        formatter: true,
        ...monthlyReportingData.months.reduce((acc, date, index) => {
          acc[`monthlyInventory_${date}_${index}`] = monthlyReportingData.boundInventory?.[index] || null; // Default to null if undefined
          return acc;
        }, {}),
      },
      {
        boundTiv: 'Difference',
        formatter: true,
        ...monthlyReportingData.months.reduce((acc, date, index) => {
          acc[`monthlyInventory_${date}_${index}`] = monthlyReportingData.difference?.[index] || null; // Default to null if undefined
          return acc;
        }, {}),
      },
      {
        boundTiv: 'Rate',
        formatter: true,
        ...monthlyReportingData.months.reduce((acc, date, index) => {
          acc[`monthlyInventory_${date}_${index}`] = monthlyReportingData.rate?.[index] || null; // Default to null if undefined
          return acc;
        }, {}),
      },
      {
        boundTiv: 'Premium Due',
        formatter: true,
        ...monthlyReportingData.months.reduce((acc, date, index) => {
          acc[`monthlyInventory_${date}_${index}`] = monthlyReportingData.premiumDue?.[index] || null; // Default to null if undefined
          return acc;
        }, {}),
      },
    ];

    return [...baseRowData, {}, totalRow, percentageRow, checkBoxRow, {}, ...additionalRows, monthLabelRow, ...summaryRows];
  }, [monthlyReportingData, policy]);

  const getRowHeight = (params) => {
    if (params.data.address === 'Create MCE' || params.data.name === 'Combine Locations') {
      return 80;
    }
  };

  useEffect(() => {
    policy.locations.length && setRowData(mapRowData());
  }, [policy, mapRowData]);

  const handleClose = () => {
    setShowCombineLocationsModal(false);
  };
  return (
    <div className="ag-theme-quartz" style={{ minHeight: '500px' }}>
      <Grid
        data={rowData}
        columns={monthlyReportingData && createColumns()}
        floatingFilter={false}
        setGridReference={setGridReference}
        shouldShowPagination={false}
        getRowHeight={getRowHeight}
      />
      {showModal && (
        <CreateRetroMCEModal showModal={showModal} setShowModal={setShowModal} policyId={policy.id} getPayload={getPayload} createMCE={createMCE} />
      )}
      {showCombineLocationsModal && <CombineLocationModal showCombineLocationsModal={showCombineLocationsModal} handleClose={handleClose} />}
    </div>
  );
});
