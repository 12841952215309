export function hasMixedDataTypes(values) {
  const typeSet = new Set();

  for (const value of values) {
    if (value === null) {
      typeSet.add('null');
    } else {
      typeSet.add(typeof value);
    }
    const isTypeNullOrString = typeSet.has('string') || typeSet.has('null');
    if (typeSet.has('number') && isTypeNullOrString) {
      return true;
    }
  }

  return false;
}
