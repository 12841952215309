export const formatAddress = (address) => {
  if (!address) return '';
  let parts = address.split(',');

  if (parts.length > 1) {
    //having in last or maybe in second last part
    let lastPart = parts[parts.length - 1].trim();
    let secondLastPart = parts[parts.length - 2].trim();

    if (lastPart.includes('.')) {
      let fixedZipCode = parseInt(lastPart).toString();

      parts[parts.length - 1] = fixedZipCode;
    } else if (secondLastPart.includes('.')) {
      let fixedZipCode = parseInt(secondLastPart).toString();
      parts[parts.length - 2] = ' ' + fixedZipCode;
    }
  }
  return parts.join(',');
};
