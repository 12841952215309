import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, GridModal, Text, Menu, MenuItem, ActivityIndicator, Box } from '../ui';
import { useEventCallback } from '../ui/hooks';
import { isObject } from '../ui/utils';
import { LayoutBox } from './Layout';
import { useAdjustmentState } from '../pages/QuotePage/context/QuoteAdjustment/AdjustmentContext';
import { useAdjustmentForm } from '../pages/QuotePage/context/QuoteAdjustment/AdjustmentFormContext';
import MacavityTracking from './ProgressBar';
import { View } from 'react-native-web';

function ViewTabs(props) {
  const {
    tabs: tabsProp,
    getLabel,
    onTabChanged,
    TabButtonProps,
    gridReference,
    endorsementGridReference,
    selectedFields,
    setSelectedFields,
    setQuoteData,
    quoteData,
    importModal,
    setImportModal,
    quotesView = false,
    onExport,
    isAdjustmentSuccessFul,
    adjustmentResponse,
    setAdjustmentResponse,
    updateMacavity,
    setShowSplitModal = () => {},
    showSplitModal,
    setCurrentTab = () => {},
    currentTab,
    isEditingMCE,
    handleDeleteFee = () => {},
    handleDeleteMCELocation = () => {},
    exportInProgress,
    handleDeleteInsureds,
    handleDeleteLossPayee,
    enableDelete,
    handleDeleteHVV,
    handleDeleteTemporaryLocations,
    ...rest
  } = props;
  const btnRef = useRef(null);
  const { adjusting, setCanExecuteBind, setCanSaveAdjustment, setCanExportEndorsements } = useAdjustmentState();
  const { setValue } = useAdjustmentForm();
  const [open, setOpen] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const tabs = useMemo(() => {
    if (Array.isArray(tabsProp)) {
      return tabsProp.map((t, i) => {
        if (isObject(t)) {
          return t;
        }
        const label = `${t}`;
        return { label, name: label };
      });
    }
    return [];
  }, [tabsProp]);

  const [tab, setTab] = useState(currentTab || tabs[0].name);

  useEffect(() => {
    if (tab !== 'BIND_FORMS' && quotesView) setCanExecuteBind(false);
    if (tab === 'MISC_VALUES' && quotesView) setCanSaveAdjustment(true);
    if (tab !== 'MISC_VALUES' && quotesView) setCanSaveAdjustment(false);
  }, [tab, quotesView, setCanExecuteBind, setCanSaveAdjustment]);

  const updateViewTab = useEventCallback((t) => onTabChanged && onTabChanged(t));

  useEffect(() => {
    updateViewTab(tab);
  }, [updateViewTab, tab]);

  useEffect(() => {
    currentTab && setTab(currentTab);
  }, [currentTab]);

  const toggleDropdown = () => {
    setMenuOpen(!menuOpen);
  };

  const handleImportClick = () => {
    setImportModal(true);
    toggleDropdown();
  };

  const handleTemplateDownload = () => {
    let headersCsv;
    if (tab === 'FEES') {
      // Custom headers for the fees table
      const customHeaders = ['FEE', 'Annual', 'Percentage of GWP'];
      headersCsv = customHeaders.join(',');
    } else {
      // Default headers from column definitions
      const gridApi = gridReference.api;
      const columnDefs = gridApi.getColumnDefs();

      if (!columnDefs || columnDefs.length === 0) {
        console.error('No column definitions found.');
        return;
      }

      const headerNames = columnDefs
        .filter((column) => column.headerName) // Ensure headerName exists
        .map((column) => column.headerName);

      if (headerNames.length === 0) {
        console.error('No header names found.');
        return;
      }

      headersCsv = headerNames.join(',');
    }

    const blob = new Blob([headersCsv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', `${tab}_headers.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!tabs.length) {
    return null;
  }

  const handleDeleteRow = (e) => {
    const selectTable = {
      FEES: 'fees',
      COVERAGES: 'locations',
    };
    const currentTable = selectTable[tab];
    e.preventDefault();
    const result = quoteData[currentTable].filter((item) => !selectedFields.find((selectedItem) => selectedItem.id === item.id));
    adjusting && setValue(`quote.${currentTable}`, result);
    setQuoteData((pre) => {
      return { ...pre, [currentTable]: result };
    });
    setOpen(false);
  };

  const handleDeleteConfirmation = async (e) => {
    try {
      setIsDeleteInProgress(true);

      if (isEditingMCE) {
        if (tab === 'PREMIUM_CALCULATION') {
          await handleDeleteMCELocation();
        } else {
          await handleDeleteFee(e);
        }
      } else if (tab === 'NAMED_INSURED') {
        handleDeleteInsureds();
      } else if (tab === 'HIGH_VALUE_VEHICLES') {
        handleDeleteHVV();
      } else if (tab === 'TEMPORARY_LOCATIONS') {
        handleDeleteTemporaryLocations();
      } else if (tab === 'LOSS_PAYEE') {
        handleDeleteLossPayee();
      } else {
        handleDeleteRow(e);
      }
    } catch (error) {
      console.error('Error during delete operation:', error);
      alert('An error occurred while processing the delete operation. Please try again.');
    } finally {
      setOpen(false);
      setIsDeleteInProgress(false); // Stop loading
    }
  };
  return (
    <LayoutBox row ml="$-2" gap={8} {...rest} style={{ overflow: 'auto', display: 'flex', alignItems: 'center' }} width="100%">
      <LayoutBox style={{ flex: 1, overflow: 'hidden' }}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            scrollbarWidth: 'thin',
          }}
        >
          {tabs.map((t) => {
            const label = resolveLabel(t, getLabel);
            if (!label) {
              return null;
            }
            return (
              <Button
                key={`ViewTab - ${t.name}`}
                onPress={() => {
                  setTab(t.name);
                  setCurrentTab(t.name);
                }}
                label={label}
                variant="text"
                paddingVertical={15}
                color={tab === t.name ? '#000' : '#657583'}
                size="medium"
                padding={'10px'}
                border={{
                  bottom: {
                    width: 3,
                    color: tab === t.name ? '$primary' : 'transparent',
                  },
                }}
                borderBottomRadius={0}
                {...TabButtonProps}
              />
            );
          })}
        </Box>{' '}
      </LayoutBox>
      <LayoutBox style={{ display: 'flex', gap: '8px', flexShrink: 0 }}>
        <LayoutBox row>
          <GridModal
            open={open}
            title="Are You Sure You Want to Delete?"
            onClose={() => {
              setOpen(false);
            }}
            buttonText="Delete Permanently"
            deleteModal={true}
            handlePress={handleDeleteConfirmation}
            capitalizeTitle={true}
            loading={isDeleteInProgress}
            disabled={isDeleteInProgress}
          >
            <Text align="left" size="large" style={{ fontWeight: 500, padding: 16 }}>
              This action cannot be undone.
            </Text>
          </GridModal>
          {(selectedFields?.length > 0 && adjusting) ||
          (enableDelete && (tab === 'NAMED_INSURED' || tab === 'HIGH_VALUE_VEHICLES' || tab === 'TEMPORARY_LOCATIONS' || tab === 'LOSS_PAYEE')) ? (
            <Button color="$primary.light" variant="text" label="Delete" onPress={() => setOpen(true)} />
          ) : null}
          {quotesView && (
            <MacavityTracking
              quoteId={quoteData.id}
              tab={tab}
              quoteData={quoteData}
              isAdjustmentSuccessFul={isAdjustmentSuccessFul}
              adjustmentResponse={adjustmentResponse}
              setAdjustmentResponse={setAdjustmentResponse}
              updateMacavity={updateMacavity}
            />
          )}
          {tab !== 'BIND_FORMS' && quotesView && (
            <Button
              color="$gray-900"
              ref={btnRef}
              variant="text"
              label={
                exportInProgress ? (
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={{ marginRight: 6, fontWeight: 'bold' }}>Exporting</Text>
                    <ActivityIndicator />
                  </View>
                ) : adjusting && (tab === 'FEES' || tab === 'COVERAGES' || tab === 'ENDORSEMENTS' || tab === 'MISC_VALUES') ? (
                  'Import / Export'
                ) : (
                  'Export'
                )
              }
              marginLeft="5"
              borderRadius="6px"
              fontWeight="700"
              onPress={() => {
                tab === 'FEES' || tab === 'COVERAGES' ? setMenuOpen(true) : onExport(tab);
                if (tab === 'ENDORSEMENTS') setCanExportEndorsements(true);
              }}
              height="35px"
              marginTop="5px"
              marginBottom="5px"
              border={{
                width: 2,
                color: '#919EAB52',
              }}
              marginRight="10"
              paddingBottom="3"
              paddingTop="3"
              disabled={exportInProgress}
              loading={exportInProgress}
            />
          )}
          <Menu
            open={menuOpen}
            anchorNode={btnRef.current}
            onClose={() => setMenuOpen(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            rounded={8}
          >
            <MenuItem
              onPress={() => {
                handleTemplateDownload();
                setMenuOpen(false);
              }}
              width={256}
              height={58}
            >
              <Text paddingLeft={15}>Download Template</Text>
            </MenuItem>
            <MenuItem
              onPress={() => {
                if (tab === 'ENDORSEMENTS' && endorsementGridReference?.api) {
                  endorsementGridReference.api.exportDataAsCsv({
                    processCellCallback: (params) => {
                      if (params.column.getColDef().exportValueFormatter) return params.column.getColDef().exportValueFormatter(params);
                      return params.value;
                    },
                  });
                } else onExport(tab);
                setMenuOpen(false);
                setCanExportEndorsements(false);
              }}
            >
              <Text paddingLeft={15}>Export</Text>
            </MenuItem>
            {adjusting && (tab === 'FEES' || tab === 'COVERAGES') && (
              <MenuItem
                onPress={() => {
                  handleImportClick();
                  setMenuOpen(false);
                }}
              >
                <Text paddingLeft={15}>Import</Text>
              </MenuItem>
            )}
          </Menu>
        </LayoutBox>
        {isEditingMCE && (
          <LayoutBox row>
            <Button color="$primary.light" variant="text" label="Delete" onPress={handleDeleteConfirmation} />
            {/* <Button
            color="$gray-900"
            variant="text"
            label={'Split Inventory'}
            marginLeft="5"
            borderRadius="6px"
            fontWeight="700"
            onPress={() => {
              setShowSplitModal(true);
            }}
            height="35px"
            marginTop="5px"
            marginBottom="5px"
            border={{
              width: 2,
              color: '#919EAB52',
            }}
            marginRight="10"
            paddingBottom="3"
            paddingTop="3"
          /> */}
          </LayoutBox>
        )}
      </LayoutBox>
    </LayoutBox>
  );
}

function resolveLabel(tab, getLabel) {
  let label = tab.label;
  if (getLabel && typeof getLabel === 'function') {
    label = getLabel(tab.name, tab.label);
  }
  return label;
}

export { ViewTabs };
