import React, { useCallback, useMemo } from 'react';
import { currencyFormatter } from '../../QuotePage/content/QuoteGrids/utils';
import { Grid } from '../../../ui';

export const TemporaryLocationsFees = React.forwardRef((props, ref) => {
  const { policy, setFeeGridRef, onFeeUpdate, feeGridRef, endorsementData = {} } = props;

  const { premium = 0 } = endorsementData;

  const calculateAmount = (rateOfNet, totalGrossPremium) => {
    return rateOfNet ? (Number(rateOfNet) / 100) * Number(totalGrossPremium) : 0;
  };
  const formatPercentage = (value) => {
    if (value === 0 || isNaN(value)) {
      return 'N/A';
    }
    const formattedValue = Number(value).toFixed(4);
    if (formattedValue === '0.00' && value > 0) {
      return '< 0.01%';
    }
    return `${formattedValue}%`;
  };

  const feeRows = policy?.fees
    ? policy?.fees?.map((fee) => ({
        name: fee.name,
        rateOfNet: fee?.rateOfNet || 0,
        amount: fee.amount,
        isEditable: true,
        id: fee.id,
      }))
    : [];

  const totalCost = premium + feeRows?.reduce((sum, row) => sum + row.amount, 0);
  const totalPremium = feeRows?.reduce((sum, row) => sum + row.rateOfNet, 0) + (premium / policy.netPremium) * 100;

  const setGridValues = useCallback(
    (params) => {
      const { data, newValue } = params;
      const { id } = data;
      const allFees = policy?.fees;
      const totalGrossPremium = premium;
      const editedFieldMap = {
        fee: 'name',
        amount: 'amount',
        rateOfNet: 'rateOfNet',
      };
      const editedField = editedFieldMap[params.colDef.field] || '';
      const rowIndex = allFees.findIndex((fee) => fee.id === id);
      const value = params.colDef.dataType === 'number' ? parseFloat(newValue) : newValue;
      if (rowIndex === -1) return;
      allFees[rowIndex][editedField] = isNaN(value) && editedField !== 'name' ? 0 : value;
      const updatedFee = { ...allFees[rowIndex] };
      if (editedField === 'amount') {
        updatedFee.rateOfNet = null;
      } else if (editedField === 'rateOfNet') {
        const newAmount = !isNaN(value) ? Number(calculateAmount(value, totalGrossPremium).toFixed(2)) : updatedFee.amount;
        if (value && !isNaN(value)) {
          updatedFee.amount = newAmount;
        }
      } else if (updatedFee[editedField]) updatedFee[editedField] = value;

      const updatedFees = [...feeRows];
      updatedFees[rowIndex] = updatedFee;
      onFeeUpdate(updatedFees);
    },
    [policy]
  );

  const colDefs = useMemo(() => {
    return [
      {
        headerName: 'Summary of Costs',
        field: 'name',
        width: 250,
        editable: (params) => params.data.isEditable,
        minWidth: 300,
        valueSetter: (params) => {
          setGridValues(params);
          return true;
        },
      },
      {
        headerName: 'Percentage of Premium',
        field: 'rateOfNet',
        editable: (params) => params.data.isEditable,
        valueFormatter: (params) => (params.data ? formatPercentage(params.data.rateOfNet) : ''),
        valueSetter: (params) => {
          const oldValue = params.data[params.colDef.field];
          if (oldValue === params.newValue) return false;
          params.api.flashCells({
            rowNodes: [params.node],
            columns: ['amount', 'rateOfNet'],
          });
          setGridValues(params);
          return true;
        },
      },
      {
        headerName: 'Cost',
        field: 'amount',
        editable: (params) => params.data.isEditable,
        valueFormatter: (params) => (params.value ? currencyFormatter({ field: { value: params.value }, withDecimals: true }) : 'N/A'),
        valueSetter: (params) => {
          const oldValue = params.data[params.colDef.field];
          if (oldValue === params.newValue) return false;
          params.api.flashCells({
            rowNodes: [params.node],
            columns: ['amount', 'rateOfNet'],
          });
          setGridValues(params);
          return true;
        },
      },
    ];
  }, [setGridValues]);

  const rowData = [
    { name: 'Premium', rateOfNet: (premium / policy.netPremium) * 100, amount: premium },
    ...feeRows,
    { name: 'Total', rateOfNet: totalPremium, amount: totalCost, isEditable: false },
  ];

  return (
    <div className="ag-theme-quartz" style={{ marginBottom: '10px', width: '100%' }}>
      <Grid
        data={rowData}
        columns={colDefs}
        floatingFilter={false}
        setGridReference={setFeeGridRef}
        shouldShowPagination={false}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={100}
        rowSelection="multiple"
        gridRef={feeGridRef}
      />
    </div>
  );
});
