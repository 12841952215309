import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid } from '../../ui';
import { LayoutBox } from '../../components';
import { useApi } from '../../hooks';
import { currencyFormatter } from '../QuotePage/content/QuoteGrids/utils';
import { usePolicyPageContext } from './context/PolicyPageContext';
import { uniqueId } from '../../ui/utils';

export const HighValueVehicles = React.forwardRef((props, ref) => {
  const { insertMultipleEndorsements, downloadEndorsement, getEndorsements } = useApi();
  const { policy, setGridReference } = props;
  const { endorsements = [], highValueVehicles, setHighValueVehicles, setEndorsements } = usePolicyPageContext();
  const [exposureLimit, setExposureLimit] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadingStates, setDownloadingStates] = useState({});
  const [deletedRows, setDeletedRows] = useState({});
  const HIGH_VALUE_VECHILE = 'highValueVehicle';

  useEffect(() => {
    setExposureLimit(policy?.occurrenceLimit);
  }, [policy]);

  const [tables, setTables] = useState([
    {
      highValueVehicles: highValueVehicles,
    },
  ]);

  useEffect(() => {
    if (!!endorsements.length) {
      if (Array.isArray(endorsements)) {
        const updatedTables = endorsements
          .map((endorsement) => {
            if (endorsement?.endorsementType === HIGH_VALUE_VECHILE) {
              let updatedRows = [];
              if (endorsement?.rows?.length) {
                updatedRows = endorsement.rows.map((row) => ({ ...row, id: uniqueId() }));
                setHighValueVehicles(updatedRows);
              }
              return {
                id: uniqueId(),
                highValueVehicles: updatedRows,
                ordinal: endorsement?.ordinal,
              };
            }
            return null;
          })
          .filter(Boolean);

        setTables(updatedTables);
      } else {
        const highValueVehicle = endorsements?.find(({ endorsementType }) => endorsementType === HIGH_VALUE_VECHILE);
        let updatedRows = [];
        if (highValueVehicle?.rows?.length) {
          updatedRows = highValueVehicle.rows.map((row) => ({ ...row, id: uniqueId() }));
          setHighValueVehicles(updatedRows);
        }
        setTables([
          {
            id: uniqueId(),
            highValueVehicles: updatedRows,
            ordinal: highValueVehicle.ordinal,
          },
        ]);
      }
    }
  }, [endorsements]);
  const columnDefs = [
    {
      headerName: 'Location Address',
      field: 'locationAddress',
      editable: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      valueFormatter: (field) => field.value || '',
    },
    {
      headerName: 'Vehicle Value',
      field: 'vehicleValue',
      editable: true,
      valueFormatter: (field) => {
        return currencyFormatter({ field, withDecimals: true });
      },
      dataType: 'number',
    },
    { headerName: 'Vehicle (Year, Make, Model)', field: 'vehicleDescription', editable: true, valueFormatter: (field) => field.value || '' },
    { headerName: 'VIN', field: 'vin', editable: true, valueFormatter: (field) => field.value || '' },
    {
      headerName: 'Effective At',
      field: 'effectiveAt',
      editable: true,
      dataType: 'string',
      cellEditorSelector: () => {
        return {
          component: 'agDateCellEditor',
          params: {
            dateFormat: 'YYYY-MM-DD',
          },
        };
      },
      valueSetter: (params) => {
        let dateValue = params.newValue;

        if (params.newValue instanceof Date) {
          dateValue = params.newValue.toISOString().split('T')[0];
        }

        if (typeof dateValue === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(dateValue)) {
          params.data.effectiveAt = dateValue;
          return true;
        }

        return false;
      },
      cellDataType: 'string',
    },
    {
      headerName: 'Effective Until',
      field: 'effectiveUntil',
      editable: true,
      dataType: 'string',
      cellEditorSelector: () => {
        return {
          component: 'agDateCellEditor',
          params: {
            dateFormat: 'YYYY-MM-DD',
          },
        };
      },
      valueSetter: (params) => {
        let dateValue = params.newValue;

        if (params.newValue instanceof Date) {
          dateValue = params.newValue.toISOString().split('T')[0];
        }

        if (typeof dateValue === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(dateValue)) {
          params.data.effectiveUntil = dateValue;
          return true;
        }

        return false;
      },
      cellDataType: 'string',
    },
  ];
  const handleDeleteRows = (tableId) => {
    const rowsToDelete = deletedRows[tableId] || [];
    if (!rowsToDelete.length) {
      alert('Please select rows to delete.');
      return;
    }

    setTables((prevTables) =>
      prevTables.map((table) => {
        if (table.id === tableId) {
          const updatedHighValueVehicles = table.highValueVehicles.filter((row) => !rowsToDelete.some((deletedRow) => deletedRow.id === row.id));

          return { ...table, highValueVehicles: updatedHighValueVehicles };
        }
        return table;
      })
    );

    setDeletedRows((prevState) => {
      const newState = { ...prevState };
      delete newState[tableId];
      return newState;
    });
  };
  const handleAddRow = (tableId) => {
    const newRow = {
      id: uniqueId(),
      locationAddress: '',
      vehicleDescription: '',
      vin: '',
      vehicleValue: 0,
      effectiveAt: '',
      effectiveUntil: '',
    };

    setTables((prevTables) =>
      prevTables.map((table) => (table.id === tableId ? { ...table, highValueVehicles: [...table.highValueVehicles, newRow] } : table))
    );
  };
  const onSelectionChanged = useCallback((context, id) => {
    if (context) {
      const selectedRows = context.api.getSelectedRows();
      setDeletedRows((prevState) => ({
        ...prevState,
        [id]: selectedRows,
      }));
    }
  }, []);

  const defaultColDef = {
    flex: 1,
    minWidth: 150,
    resizable: true,
  };

  const handleOnSave = async () => {
    setIsLoading(true);

    const endorsements = tables.map((table) => ({
      endorsementType: HIGH_VALUE_VECHILE,
      notes: table.notes,
      ordinal: table.ordinal,
      rows: table.highValueVehicles
        .filter((row) => {
          return Object.entries(row).some(([key, value]) => {
            if (key === 'id') return false;
            return value !== '' && value !== null && value !== undefined && !(typeof value === 'number' && value === 0);
          });
        })
        .map((row) => ({
          locationAddress: row.locationAddress,
          vehicleDescription: row.vehicleDescription,
          vin: row.vin,
          vehicleValue: row.vehicleValue,
          effectiveAt: row.effectiveAt,
          effectiveUntil: row.effectiveUntil,
        })),
    }));

    const payloads = {
      endorsements,
      occurrenceLimit: exposureLimit,
    };

    try {
      const response = await insertMultipleEndorsements(policy.id, payloads, HIGH_VALUE_VECHILE);
      if (response) {
        const endorsement = await getEndorsements(policy.id);
        setEndorsements(endorsement);
      }
      alert('Saved Successfully.');
    } catch (error) {
      console.error('Error saving payloads:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const gridOptions = {
    enableCellChangeFlash: true,
  };
  const handleOnDownload = async (tableId) => {
    setDownloadingStates((prev) => ({ ...prev, [tableId]: true }));

    const selectedTable = tables.find((table) => table.id === tableId);
    if (!selectedTable) return;

    const payload = {
      endorsementType: HIGH_VALUE_VECHILE,
      rows: selectedTable.highValueVehicles
        .filter((row) => {
          return Object.entries(row).some(([key, value]) => {
            if (key === 'id') return false;
            return value !== '' && value !== null && value !== undefined && !(typeof value === 'number' && value === 0);
          });
        })
        .map((row) => ({
          locationAddress: row.locationAddress,
          vehicleDescription: row.vehicleDescription,
          vin: row.vin,
          vehicleValue: row.vehicleValue,
          effectiveAt: row.effectiveAt,
          effectiveUntil: row.effectiveUntil,
        })),
    };

    try {
      const response = await downloadEndorsement(policy.id, payload);
      if (response) {
        const downloadUrl = response.data.value;
        window.open(downloadUrl, '_blank');
      }
    } catch (error) {
      console.error('Error saving payload:', error);
    } finally {
      setDownloadingStates((prev) => ({ ...prev, [tableId]: false }));
    }
  };
  const exposureLimitColumns = [
    {
      headerName: 'Exposure Limit',
      field: 'exposureLimit',
      editable: true,
      cellStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      },
      valueFormatter: (field) => currencyFormatter({ field, withDecimals: true }),
    },
  ];
  const handleAddNewTable = () => {
    const highValueVehicle = endorsements?.find(({ endorsementType }) => endorsementType === HIGH_VALUE_VECHILE);
    const mappedData = highValueVehicle?.rows?.map((row) => ({ ...row, id: uniqueId() })) || [];
    const newTable = {
      id: uniqueId(),
      highValueVehicles: mappedData,
    };

    setTables((prev) => [...prev, newTable]);
  };

  return (
    <Box>
      <Box
        style={{
          padding: '16px',
          border: '1px solid #ddd',
          borderRadius: '8px',
          margin: '10px',
          background: '#f9f9f9',
        }}
      >
        <div className="ag-theme-alpine" style={{ height: '60px', width: '100%', marginBottom: '20px' }}>
          <Grid
            data={[{ exposureLimit: exposureLimit }]}
            columnDefs={exposureLimitColumns}
            defaultColDef={{
              flex: 1,
              resizable: true,
            }}
            setGridReference={setGridReference}
            onCellValueChanged={(params) => {
              if (params.colDef.field === 'exposureLimit') {
                setExposureLimit(params.newValue);
              }
            }}
            floatingFilter={false}
            shouldShowPagination={false}
          />
        </div>
      </Box>
      {tables.map((table, index) => (
        <Box
          key={table.id}
          style={{
            padding: '16px',
            border: '1px solid #ddd',
            borderRadius: '8px',
            margin: '10px',
            background: '#f9f9f9',
          }}
        >
          <input
            type="text"
            defaultValue={`HVV_${index + 1}`}
            value={table?.notes}
            onChange={(e) => {
              const updatedTables = tables.map((t) => (t.id === table.id ? { ...t, notes: e.target.value } : t));
              setTables(updatedTables);
            }}
            placeholder="Add a note..."
            style={{
              width: '100%',
              padding: '10px',
              fontSize: '14px',
              fontWeight: 'bold',
              border: '1px solid #ccc',
              borderRadius: '6px',
              background: '#fff',
              outline: 'none',
              transition: 'border-color 0.2s ease-in-out',
              marginBottom: '10px',
            }}
            onFocus={(e) => (e.target.style.borderColor = '#007bff')}
            onBlur={(e) => (e.target.style.borderColor = '#ccc')}
          />

          <div className="ag-theme-quartz" style={{ maxHeight: '600px', overflowY: 'auto', minHeight: '200px' }}>
            <Grid
              data={table.highValueVehicles}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              domLayout="autoHeight"
              setGridReference={setGridReference}
              gridOptions={gridOptions}
              rowSelection="multiple"
              shouldShowPagination={false}
              columnWidth={150}
              enableRangeSelection={true}
              floatingFilter={false}
              onCellValueChanged={(params) => {
                const updatedTables = tables.map((t) =>
                  t.id === table.id
                    ? {
                        ...t,
                        highValueVehicles: t.highValueVehicles.map((vehicle, rowIndex) => (rowIndex === params.rowIndex ? params.data : vehicle)),
                      }
                    : t
                );
                setTables(updatedTables);
              }}
              onSelectionChanged={(context) => onSelectionChanged(context, table.id)}
            />
          </div>

          <LayoutBox row justifyContent="flex-start" alignItems="center" flexWrap="wrap" gap={6}>
            <Box border="1px solid #919EAB52" borderRadius={8} margin="$1">
              <Button
                label="Add Vehicle"
                color="$primary"
                variant="text"
                size="small"
                minWidth={100}
                minHeight={28}
                onPress={() => handleAddRow(table.id)}
              />
            </Box>

            <Box border="1px solid #919EAB52" borderRadius={8} margin="$1">
              <Button
                label={downloadingStates[table.id] ? '' : 'Download'}
                color="$primary"
                variant="text"
                size="small"
                minWidth={80}
                minHeight={28}
                loading={downloadingStates[table.id]}
                disabled={downloadingStates[table.id]}
                onPress={() => handleOnDownload(table.id)}
              />
            </Box>
            <Box border="1px solid #e74c3c" borderRadius={8} margin="$1">
              <Button
                label="Delete Row(s)"
                color="red"
                variant="text"
                size="small"
                minWidth={80}
                minHeight={28}
                onPress={() => {
                  handleDeleteRows(table.id);
                }}
              />
            </Box>
            <Box border="1px solid #e74c3c" borderRadius={8} margin="$1">
              <Button
                label="Delete Table"
                color="red"
                variant="text"
                size="small"
                minWidth={80}
                minHeight={28}
                onPress={() => {
                  setTables(tables.filter((t) => t.id !== table.id));
                }}
              />
            </Box>
          </LayoutBox>
        </Box>
      ))}
      <LayoutBox row gap={8}>
        <Box border="1px solid #919EAB52" borderRadius={8} margin="$2" width="150px">
          <Button label="Add New HVV" color="$primary" paddingX={16} minHeight={40} minWidth={120} onPress={handleAddNewTable} />
        </Box>
        <Box border="1px solid #919EAB52" borderRadius={8} margin="$2">
          <Button
            label={isLoading ? '' : 'Save'}
            color="$primary"
            variant="text"
            paddingX={16}
            minHeight={40}
            minWidth={120}
            onPress={handleOnSave}
            loading={isLoading}
            disabled={isLoading}
          />
        </Box>
      </LayoutBox>
    </Box>
  );
});
