import { AgGridReact } from 'ag-grid-react';
import { ActivityIndicator, Button, GridModal } from '../../../ui';
import { useEffect, useRef, useState } from 'react';
import MultiSelect from './MultiSelect';
import { EditIcon, TrashIcon } from '../../../components/icons';
import { usePolicyPageContext } from '../context/PolicyPageContext';
import { uniqueId } from '../../../ui/utils';
import { CheckBox, Pressable, Text, View } from 'react-native-web';
import { useApi } from '../../../hooks';

const LocationDropdown = ({ selectedValue, setSelectedValue, options }) => {
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const filteredOptions = options.filter((loc) => loc.id !== selectedValue);
  return (
    <select
      value={selectedValue || ''}
      onChange={handleChange}
      style={{
        width: '100%',
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxSizing: 'border-box',
      }}
    >
      {selectedValue && (
        <option value={selectedValue} disabled>
          {`${options.find((loc) => loc.id === selectedValue)?.riskId}. ${
            options.find((loc) => loc.id === selectedValue)?.name || 'Selected Location'
          }`}
        </option>
      )}

      <option value="">Select Location</option>

      {filteredOptions.map((loc) => (
        <option key={loc.id} value={loc.id}>
          {`${loc?.riskId}. ${loc.name}`}
        </option>
      ))}
    </select>
  );
};

const ActionButtons = (params, setGroupsData) => {
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    setSelectedOption(params.data.distribute || '');
  }, [params.data]);

  const handleEdit = () => {
    params.api.startEditingCell({ rowIndex: params.rowIndex, colKey: 'name' });
  };

  const handleDelete = () => {
    params.api.applyTransaction({ remove: [params.data] });
    setGroupsData((prev) => prev.filter((group) => group.id !== params.data.id));
  };

  const handleCheckboxChange = (option) => {
    const newOption = selectedOption === option ? '' : option;
    setSelectedOption(newOption);

    params.api.applyTransaction({
      update: [{ ...params.data, distribute: newOption }],
    });
    setGroupsData((prevGroups) =>
      prevGroups.map((group) =>
        group.id === params.data.id
          ? {
              ...group,
              distribute: group.distribute === option ? '' : option,
            }
          : group
      )
    );
  };

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center', gap: 8, padding: 6, marginTop: 8 }}>
      <View style={{ flexDirection: 'column', alignItems: 'flex-start', flex: 1, gap: 8 }}>
        {[
          { key: 'inherit', label: 'Inherit TIV' },
          { key: 'proportional', label: 'Proportional TIV' },
        ].map((option) => (
          <View key={option.key} style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}>
            <CheckBox
              value={selectedOption === option.key}
              onValueChange={() => handleCheckboxChange(option.key)}
              tintColors={{ true: 'rgb(95, 41, 148)', false: '#ccc' }}
              color={selectedOption === option.key ? 'rgb(95, 41, 148)' : '#ccc'}
            />
            <Text style={{ fontSize: 14, color: '#333' }}>{option.label}</Text>
          </View>
        ))}
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center', gap: 6 }}>
        <Pressable
          onPress={handleEdit}
          style={({ pressed }) => ({
            padding: 6,
            borderRadius: 6,
            borderWidth: 1,
            borderColor: '#e5e7eb',
            backgroundColor: pressed ? '#fee2e2' : '#f9fafb',
          })}
        >
          <EditIcon />
        </Pressable>
        <Pressable
          onPress={handleDelete}
          style={({ pressed }) => ({
            padding: 6,
            borderRadius: 6,
            borderWidth: 1,
            borderColor: '#e5e7eb',
            backgroundColor: pressed ? '#fee2e2' : '#f9fafb',
          })}
        >
          <TrashIcon />
        </Pressable>
      </View>
    </View>
  );
};

const ChildGrid = (params, locations, setGroupsData) => {
  const { data } = params;

  const percentageFormatter = (value) => {
    return `${value}%`;
  };
  const DeleteButton = (params) => {
    return (
      <button
        onClick={() => handleDelete(params)}
        onMouseEnter={(e) => (e.target.style.backgroundColor = '#e2e8f0')}
        onMouseLeave={(e) => (e.target.style.backgroundColor = '#f9fafb')}
        style={{
          padding: '3px',
          cursor: 'pointer',
          border: '1px solid #e5e7eb',
          borderRadius: '6px',
          backgroundColor: '#f9fafb',
        }}
      >
        <TrashIcon />
      </button>
    );
  };

  const handleDelete = (params) => {
    params.api.applyTransaction({ remove: [params.data] });
    setGroupsData((prevGroups) =>
      prevGroups.map((group) =>
        group.id === data.id
          ? {
              ...group,
              children: group.children.filter((child) => child.locationId !== params.data.locationId),
            }
          : group
      )
    );
  };

  const childColumnDefs = [
    {
      field: 'name',
      headerName: 'Location',
      rowDrag: true,
      valueGetter: (params) => {
        return params?.data?.name || locations.find((location) => location.id === params.data.locationId).name;
      },
    },
    {
      field: 'amount',
      headerName: 'Flat Amount ($)',
      editable: !params.data.distribute,
      valueFormatter: (params) => {
        return params?.data?.amount ? `$${Number(params?.data?.amount)?.toFixed(2)}` : '';
      },
      cellClassRules: {
        'ag-cell-disable': () => params.data.distribute,
        'ag-cell-editable': () => !params.data.distribute,
      },
      dataType: 'number',
    },
    {
      field: 'percentage',
      headerName: 'Percentage (%)',
      editable: !params.data.distribute,
      cellClassRules: {
        'ag-cell-disable': () => params.data.distribute,
        'ag-cell-editable': () => !params.data.distribute,
      },
      valueFormatter: (params) => params.data.percentage && percentageFormatter(params.data.percentage),
      dataType: 'number',
    },
    { headerName: 'Actions', cellRenderer: DeleteButton },
  ];

  const handleChildGridChange = (params) => {
    const { data, colDef, newValue } = params;
    if (colDef.field === 'amount' && newValue) {
      data.percentage = null;
    } else if (colDef.field === 'percentage' && newValue) {
      data.amount = null;
    }
  };

  return (
    <div
      style={{
        height: '100%',
        maxHeight: '280px',
        overflow: 'auto',
        padding: '10px',
        border: '1px solid #e5e7eb',
        borderRadius: '6px',
        backgroundColor: '#f9fafb',
      }}
    >
      <AgGridReact
        rowData={data.children}
        columnDefs={childColumnDefs}
        defaultColDef={{ flex: 1, minWidth: 100 }}
        domLayout="autoHeight"
        rowDragManaged={true}
        onCellValueChanged={handleChildGridChange}
        frameworkComponents={{ DeleteButton, LocationDropdown, ActionButtons }}
      />
    </div>
  );
};

const CombineLocationModal = ({ showCombineLocationsModal, handleClose = () => {} }) => {
  const { MCEsData, policy, groups = [], fetchAndSetGroups, isFetchingGroups } = usePolicyPageContext();
  const { updateGroups } = useApi();
  const executedMCEs = MCEsData.filter((mce) => mce.type === 'locationChange' && mce.status === 'executed');
  executedMCEs.sort((a, b) => new Date(b.effectiveAt) - new Date(a.effectiveAt));
  const mostRecentExecutedMCE = executedMCEs[0];
  const locations = mostRecentExecutedMCE?.locations?.length > 0 ? mostRecentExecutedMCE?.locations : policy?.locations;
  const [groupsData, setGroupsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const gridRef = useRef(null);
  const dropdownRefs = useRef({});

  useEffect(() => {
    setGroupsData(
      groups.map((group) => {
        return { ...group, id: uniqueId() };
      })
    );
  }, [groups]);

  const generatePayload = (groupsData) => {
    return groupsData.map((group) => ({
      name: group.name,
      parentId: group.parentId,
      distribute: group.distribute || '',
      children: group.children.map((child) => ({
        locationId: child.locationId,
        amount: Number(child.amount),
        percentage: Number(child.percentage),
        name: child.name,
      })),
    }));
  };

  const validatePayload = (payload) => {
    let missingParentIds = payload.filter((group) => !group.parentId).map((group) => group.name || 'Unnamed Group');

    if (missingParentIds.length > 0) {
      return `Please Select Parent Location for the following Groups: ${missingParentIds.join(', ')}.`;
    }

    return '';
  };

  const handleSaveData = async () => {
    const payload = generatePayload(groupsData);

    if (!groups.length && !payload.length) {
      setErrorMessage('Please add a group before saving.');
      return;
    }

    const error = validatePayload(payload);
    if (error) {
      setErrorMessage(error);
      setTimeout(() => setErrorMessage(''), 3000);
      return;
    }

    setLoading(true);
    setErrorMessage('');

    try {
      const response = await updateGroups(policy.id, payload);
      if (response) {
        fetchAndSetGroups();
        alert('Updated groups successfully');
      }
    } catch (err) {
      alert('Unable to create or update the groups.');
      setErrorMessage('Unable to create or update the groups.');
      setTimeout(() => setErrorMessage(''), 3000);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const addGroup = () => {
    const newGroup = {
      id: uniqueId(),
      name: `New Group ${groupsData.length + 1}`,
      parentId: '',
      selectedMonth: '',
      children: [],
    };
    setGroupsData([...groupsData, newGroup]);
  };

  const handleSelectedLocationsChange = (groupId, newSelectedLocations) => {
    setGroupsData((prevData) =>
      prevData.map((group) =>
        group.id === groupId
          ? {
              ...group,
              children: newSelectedLocations.map((loc) => ({
                locationId: loc?.id || loc?.locationId,
                amount: loc?.amount || null,
                percentage: loc?.percentage || null,
                name: loc?.name,
              })),
            }
          : group
      )
    );
  };

  const getAvailableLocations = (excludeLocationIds = []) => {
    return locations.filter((loc) => !excludeLocationIds.includes(loc.id || loc?.locationId));
  };

  const getSelectedLocations = (params) => {
    return groupsData.filter(({ parentId }) => parentId !== params?.data?.parentId).map(({ parentId }) => parentId);
  };

  const getSelectedChildLocations = (params) => {
    return groupsData
      .flatMap(({ children }) => children)
      .filter(({ locationId }) => locationId !== params?.data?.locationId)
      .map(({ locationId }) => locationId);
  };

  const columnDefs = [
    { field: 'name', headerName: 'Group Name', editable: true, cellRenderer: 'agGroupCellRenderer', minWidth: 200 },
    {
      field: 'parentId',
      headerName: 'Parent Location',
      cellRenderer: (params) => {
        const usedLocations = getSelectedLocations(params);
        const childLocations = getSelectedChildLocations(params);

        const availableParentLocations = getAvailableLocations([...usedLocations, ...childLocations]);
        return (
          <LocationDropdown
            selectedValue={params.value}
            setSelectedValue={(newValue) => {
              setGroupsData((prev) =>
                prev.map((group) =>
                  group.id === params.data.id
                    ? {
                        ...group,
                        parentId: newValue,
                        children: group?.children?.filter((child) => child.locationId !== newValue),
                      }
                    : group
                )
              );
              params.api.refreshCells({ rowNodes: [params.node], columns: ['parentId'] });
            }}
            options={availableParentLocations}
          />
        );
      },
      editable: false,
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: 'Select Child Locations',
      field: 'locations',
      width: 600,
      cellRenderer: (params) => {
        const usedLocations = getSelectedChildLocations(params);
        const parentLocations = getSelectedLocations(params);
        const availableChildLocations = getAvailableLocations([...usedLocations, ...parentLocations, params.data.parentId]);
        return (
          <MultiSelect
            ref={(el) => (dropdownRefs.current[params.node.id] = el)}
            selectedValues={locations.filter((loc) => (params.data.children || []).some((child) => child.locationId === loc.id))}
            setSelectedValues={(newValues) => {
              handleSelectedLocationsChange(params.data.id, newValues);
            }}
            options={availableChildLocations}
          />
        );
      },
      cellStyle: { overflowY: 'auto', maxHeight: 800 },
      minWidth: 200,
    },
    { headerName: 'Actions', flex: 1, cellRenderer: (params) => ActionButtons(params, setGroupsData), minWidth: 300 },
  ];

  const gridOptions = {
    masterDetail: true,
    detailCellRenderer: (params) => ChildGrid(params, locations, setGroupsData),
    getRowHeight: (params) => {
      if (params.node.detail) {
        return 400;
      }

      const selectedLocations = params.data?.children || [];
      const baseHeight = 80;
      const locationHeight = selectedLocations.length * 25;

      return Math.max(baseHeight, locationHeight);
    },
  };

  return (
    <GridModal
      title={'Combine Locations'}
      buttonText={'Submit'}
      handlePress={() => {
        handleSaveData();
      }}
      open={showCombineLocationsModal}
      onClose={() => handleClose()}
      width="90%"
      capitalizeTitle={true}
      loading={loading}
    >
      <div
        style={{
          padding: '24px',
          height: '500px',
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {isFetchingGroups ? (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            <ActivityIndicator size="large" />
            <p style={{ marginTop: '12px', fontSize: '16px', fontWeight: 'bold', color: '#374151' }}>Fetching Groups...</p>
          </div>
        ) : (
          <>
            <Button
              color="$primary"
              onPress={() => addGroup()}
              borderRadius={8}
              width="120px"
              style={{
                alignSelf: 'flex-end',
                marginBottom: '12px',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              + Add Group
            </Button>
            <div
              className="ag-theme-quartz"
              style={{
                flex: 1,
                overflowY: 'auto',
                border: '1px solid #d1d5db',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                width: '100%',
                borderRadius: '8px',
                padding: '8px',
              }}
            >
              <AgGridReact
                masterDetail={true}
                ref={gridRef}
                rowData={groupsData}
                gridOptions={gridOptions}
                columnDefs={columnDefs}
                frameworkComponents={{ LocationDropdown, ActionButtons }}
                context={{ locations: locations }}
                domLayout="autoHeight"
                defaultColDef={{ flex: 1 }}
              />
            </div>
            {errorMessage && (
              <div
                style={{
                  color: 'red',
                  marginTop: '10px',
                  padding: '6px',
                  border: '1px solid red',
                  borderRadius: '5px',
                  backgroundColor: '#ffe6e6',
                  fontSize: '12px',
                }}
              >
                {errorMessage}
              </div>
            )}
          </>
        )}
      </div>
    </GridModal>
  );
};

export default CombineLocationModal;
