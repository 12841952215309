import { useState, useRef, useEffect } from 'react';
import { FlatList, Text, TouchableOpacity, View } from 'react-native-web';
import { createPortal } from 'react-dom';

export default function MultiSelect({ selectedValues = [], setSelectedValues, options }) {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleSelection = (option) => {
    setSelectedValues([...selectedValues, option]);
  };
  const removeSelected = (id) => {
    setSelectedValues(selectedValues.filter((item) => item.id !== id));
  };

  useEffect(() => {
    if (isOpen && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <View style={{ width: '100%', padding: '8px', display: 'flex', height: '100%' }}>
      {/* Select Field */}
      <TouchableOpacity
        ref={buttonRef}
        onPress={() => setIsOpen(!isOpen)}
        style={{
          width: '100%',
          border: '1px solid rgb(204, 204, 204)',
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'wrap',
          backgroundColor: 'white',
          padding: '6px',
          borderRadius: '4px',
          minHeight: '36px',
          marginTop: '10px',
        }}
      >
        {selectedValues.length > 0 ? (
          selectedValues.map((val) => (
            <View
              key={val.id}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: 'rgba(95, 41, 148, 0.2)',
                borderRadius: 12,
                paddingVertical: 4,
                paddingHorizontal: 8,
                marginRight: 6,
                marginBottom: 4,
              }}
            >
              <Text style={{ fontSize: 12, color: 'rgb(95, 41, 148)', marginRight: 4 }}>{`${val.riskId}. ${val.name}`}</Text>
              <TouchableOpacity onPress={() => removeSelected(val.id)}>
                <Text style={{ fontSize: 12, color: 'rgb(95, 41, 148)', fontWeight: 'bold' }}>✖</Text>
              </TouchableOpacity>
            </View>
          ))
        ) : (
          <Text style={{ fontSize: 14, fontStyle: 'bold' }}>Select locations</Text>
        )}
      </TouchableOpacity>
      {isOpen &&
        createPortal(
          <View
            ref={dropdownRef}
            style={{
              position: 'absolute',
              top: dropdownPosition.top,
              left: dropdownPosition.left,
              width: dropdownPosition.width,
              backgroundColor: 'white',
              borderWidth: 1,
              borderColor: 'gray',
              borderRadius: 5,
              maxHeight: 150,
              overflowY: 'auto',
              zIndex: 1000,
              boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
            }}
          >
            {options.length === 0 ? (
              <Text style={{ fontSize: 14, color: 'gray', textAlign: 'center' }}>No locations available</Text>
            ) : (
              <FlatList
                data={options.filter((opt) => !selectedValues.some((sel) => sel.id === opt.id))} // Hide selected options
                keyExtractor={(item) => item?.id?.toString()}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    onPress={() => toggleSelection(item)}
                    style={{
                      padding: 4,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      backgroundColor: 'transparent',
                      transition: 'background-color 0.2s ease-in-out',
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = '#1967d2';
                      e.currentTarget.style.color = '#ffffff';
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = 'transparent';
                      e.currentTarget.style.color = 'black';
                    }}
                  >
                    <Text
                      style={{ fontSize: 14, fontWeight: 'normal', width: '100%', height: '100%' }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = '#1967d2';
                        e.currentTarget.style.color = '#ffffff';
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'transparent';
                        e.currentTarget.style.color = 'black';
                      }}
                    >
                      {` ${item.riskId}. ${item.name}`}
                    </Text>
                  </TouchableOpacity>
                )}
              />
            )}
          </View>,
          document.body
        )}
    </View>
  );
}
