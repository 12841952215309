import { Box, Text, GridModal, Heading } from '../ui';
import React, { memo, useMemo, useState } from 'react';
import { withStyles } from '../ui/styling';
import { StarFilledIcon, StarIcon } from './icons';
import { Chip } from './Chip';
import { Colors, TextColors } from '../constants';
import moment from 'moment-timezone';
import { LayoutBox } from './Layout';

export const ContentHeader = withStyles(({ theme }) => ({
  root: {},
  orderDetails: {
    marginBottom: '$40',
  },
  orderDetailsCard: {
    marginBottom: '$24',
    padding: '$32',
  },
  orderDiscRow: {
    display: 'flex',
    gap: '$8',
    alignItems: 'flex-start',
    marginBottom: '$16',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    flexWrap: 'wrap',
  },
  orderPointersList: {
    color: '#919EAB',
    fontSize: 14,
    lineHeight: 22,
    fontWeight: 700,
    display: 'flex',
    rowGap: 6,
    columnGap: 16,
    flexWrap: 'wrap',
  },
}))(
  React.forwardRef(function ContentHeader(props, ref) {
    const { styles, showHeroIcon, HeaderTitle, status, itemNumber, effectiveDate, type, adjustment } = props;
    const [openUpdateHeroQuoteModal, setOpenUpdateHeroQuoteModal] = useState(false);
    const [confirmUpdateHeroQuote, setConfirmUpdateHeroQuote] = useState(false);
    return (
      <Box style={{ display: 'flex' }}>
        <Box style={styles.titleContainer} flexDirection="row">
          {showHeroIcon && (
            <Box cursor="pointer" onPress={() => setOpenUpdateHeroQuoteModal(true)}>
              {confirmUpdateHeroQuote ? <StarFilledIcon /> : <StarIcon />}
            </Box>
          )}
          <Heading level={3}>{HeaderTitle}</Heading>
          <Chip color={Colors.quoteStatus[status] || '$gray.100'} textColor={TextColors[status]}>
            {status}
          </Chip>
          {type && (
            <Chip color={Colors.mceStatus[type] || '$gray.100'} textColor={TextColors[type]}>
              {type}
            </Chip>
          )}
          {status === 'Executed' && adjustment && (
            <Chip color={'#FFE4B299'} textColor={'#E0A800'}>
              Adjusted
            </Chip>
          )}
        </Box>

        <GridModal
          open={openUpdateHeroQuoteModal}
          title="Confirm Hero Quote Change"
          buttonText="Confirm"
          buttonRadius="8px"
          onClose={() => setOpenUpdateHeroQuoteModal(false)}
          handlePress={() => {
            setConfirmUpdateHeroQuote(true);
            setOpenUpdateHeroQuoteModal(false);
          }}
          capitalizeTitle={true}
        >
          <Box padX="16px" padY="16px">
            <Text value="Are you sure you wish to set this quote as the Hero Quote?" bold textAlign="left" small />
            <Text value="This will remove the Hero status from the current Hero Quote." bold textAlign="left" small />
          </Box>
        </GridModal>
      </Box>
    );
  })
);

const PageTitleAndAction = memo(function PageTitleAndAction({
  data,
  updating,
  update,
  lastUpdated,
  printRef,
  expiration,
  actionButtons,
  effectiveDate,
  itemNumber,
  HeaderTitle,
  ...rest
}) {
  const historyDisplayText = useMemo(() => {
    if (!data?.lastChangeAt || data?.createdAt) return;
    if (data) {
      const lastTimestamp = data.lastChangeAt ? moment(data.lastChangeAt) : data.createdAt ? moment(data.createdAt) : null;
      if (lastTimestamp) {
        const now = moment();
        const dayDiff = now.diff(lastTimestamp, 'days');
        if (dayDiff < 2) {
          const hourDiff = now.diff(lastTimestamp, 'hours');
          if (hourDiff < 1) {
            const minuteDiff = now.diff(lastTimestamp, 'minutes');
            if (minuteDiff <= 1) {
              return `Updated < 1 minute ago`;
            }
            return `Updated ${minuteDiff} minutes ago`;
          }
          return `Updated ${hourDiff} hour${hourDiff > 1 ? 's' : ''} ago`;
        } else if (dayDiff >= 9) {
          const monthDiff = now.diff(lastTimestamp, 'months');
          if (monthDiff >= 6) {
            return `Last updated ${lastTimestamp.format('M-D-YYYY')}`;
          }
          return `Last updated ${lastTimestamp.format('MMM Do')}`;
        }
        return `Updated ${now.diff(lastTimestamp, 'days')} days ago`;
      }
    }

    return 'Update History';
  }, [data]);

  return (
    <>
      {data ? (
        <Box xsHeight={64} bg="#F9FAFB">
          <LayoutBox row width="100%">
            <Box width="50%">
              <ContentHeader
                data={data}
                expiration={expiration?.text}
                historyDisplayText={historyDisplayText}
                effectiveDate={effectiveDate}
                HeaderTitle={HeaderTitle}
                {...rest}
              />
            </Box>
            <Box width="50%">{actionButtons}</Box>
          </LayoutBox>
          <LayoutBox
            row
            style={{ color: '#919EAB', fontSize: 14, lineHeight: 22, fontWeight: 700, display: 'flex', rowGap: 6, columnGap: 16, flexWrap: 'wrap' }}
          >
            <span style={{ color: '#212B36 ' }}>Created {moment.utc(effectiveDate).format('MMM D, YYYY')}</span>
            {expiration?.text && <li>{expiration?.text}</li>}
            <li>{itemNumber}</li>
            {historyDisplayText && <li>{historyDisplayText}</li>}
            <li>{HeaderTitle}</li>
          </LayoutBox>
        </Box>
      ) : null}
    </>
  );
});

export default PageTitleAndAction;
