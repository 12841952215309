import React, { useState, useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button } from '../../ui';
import { usePolicyPageContext } from './context/PolicyPageContext';
import { useApi } from '../../hooks';
import moment from 'moment-timezone';
import { LayoutBox } from '../../components';
import { v4 as uuid } from 'uuid';

export const PolicyGeneration = () => {
  const { endorsements = [], policy } = usePolicyPageContext();
  const { downloadPolicyWithEndorsements, updateOrdinals } = useApi();
  const [isDownloading, setIsDownloading] = useState(false);
  const [savingOrders, setSavingOrders] = useState(false);

  const [rowData, setRowData] = useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [ordinalsData, setOrdinalsData] = useState([]);

  const formatTitle = (text) => {
    return text.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
  };

  const validEndorsements = endorsements.filter((e) => e?.endorsementType && e.endorsementType !== 'mce');
  const groupedEndorsements = validEndorsements.reduce((acc, endorsement) => {
    const key = endorsement.endorsementType;
    if (!acc[key]) acc[key] = [];
    acc[key].push(endorsement);
    return acc;
  }, {});

  const getName = ({ endorsementType, endorsementData, notes, subIdx }) => {
    if (['highValueVehicle', 'lenderLossPayee', 'temporaryLocations'].some((type) => endorsementType?.includes(type)))
      return `${formatTitle(endorsementType)} ${subIdx + 1}`;
    else if (endorsementType === 'MceForm')
      return `MISCELLANEOUS CHANGE ENDORSEMENT -\nINVENTORY SCHEDULE FLUCTUATION -\n${formatDate(endorsementData?.effectiveAt)} - ${formatDate(
        endorsementData?.effectiveUntil || moment(policy.effectiveUntil).format('YYYY-MM-DD')
      )}`;
    else return notes || `${endorsementType} ${subIdx + 1}`;
  };

  const indexedEndorsements = Object.values(groupedEndorsements).flatMap((group, idx) =>
    group.map((endorsement, subIdx) => ({
      ...endorsement,
      subIdx,
    }))
  );
  function formatDate(dateString) {
    if (!dateString) return;
    const [year, month, day] = dateString?.split('-');
    const date = new Date(year, month - 1, day);
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    return formattedDate.toUpperCase();
  }
  const allEndorsements = [...indexedEndorsements];
  const initialRowData = useMemo(
    () =>
      allEndorsements.map(({ ordinal, endorsementType, formId, endorsementData, notes, rows, subIdx }, index) => ({
        ordinal: ordinal || index + 1,
        id: uuid(),
        name: getName({ endorsementType, formId, endorsementData, subIdx, notes }),
        mceNumber: endorsementType === 'MceForm' ? notes : '',
        formId,
        ...(endorsementData && { endorsementData }),
        endorsementType,
        ...(notes && { notes }),
        ...(rows && { rows }),
      })),
    [allEndorsements]
  );

  useState(() => {
    setRowData(initialRowData);
  }, [initialRowData]);

  const onSelectionChanged = useCallback((params) => {
    const selectedNodes = params.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    setSelectedForms(selectedData);
  }, []);

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      const payload = selectedForms
        .map(({ ordinal, endorsementData, endorsementType, formId, notes, rows }) => ({
          ordinal,
          endorsementType,
          formId,
          ...(endorsementData && { endorsementData }),
          ...(notes && { notes }),
          ...(rows && { rows }),
        }))
        .sort((a, b) => Number(a.ordinal) - Number(b.ordinal));

      const { data } = await downloadPolicyWithEndorsements(policy.id, payload);
      if (data?.value) window.open(data.value, '_blank');
    } catch {
      alert('Unable to download, please try again later.');
    } finally {
      setIsDownloading(false);
    }
  };

  const handleSaveOrder = async () => {
    setSavingOrders(true);
    try {
      const payload = ordinalsData.sort((a, b) => Number(a.ordinal) - Number(b.ordinal));
      await updateOrdinals(policy.id, payload);
      alert('Saved Successfully ');
    } catch {
      alert('Unable to Save, please try again later.');
    } finally {
      setSavingOrders(false);
    }
  };
  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        field: 'selected',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        width: 50,
      },
      {
        headerName: 'Order',
        field: 'ordinal',
        editable: (params) => params.data?.endorsementType !== 'PolicyForm',
        width: 200,
        rowDrag: (params) => params.data?.endorsementType !== 'PolicyForm',
        sortable: true,
        sort: 'asc',
        comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
        valueSetter: (params) => {
          const oldValue = params.oldValue;
          const newValue = params.newValue;
          const rowId = params.data.id;

          setOrdinalsData((prevData) => {
            const existingEntryIndex = prevData.findIndex((entry) => entry.data?.id === rowId);

            if (existingEntryIndex >= 0) {
              const updatedData = [...prevData];
              updatedData[existingEntryIndex] = {
                ...updatedData[existingEntryIndex],
                newOrdinal: newValue,
                formId: params.data.formId,
              };
              return updatedData;
            } else {
              return [
                ...prevData,
                {
                  ordinal: oldValue,
                  newOrdinal: newValue,
                  formId: params.data.formId,
                },
              ];
            }
          });

          params.data.ordinal = newValue;
          return true;
        },
        cellClassRules: {
          'ag-cell-editable': (params) => {
            return params.data?.endorsementType !== 'PolicyForm';
          },
        },
      },

      { headerName: 'Name', field: 'name', minWidth: 850 },
      {
        field: 'mceNumber',
        headerName: 'MCE Number',
        suppressRowClickSelection: true,
        flex: 1,
      },
    ],
    []
  );

  const defaultColDef = useMemo(() => ({ sortable: true, resizable: true }), []);

  const onGridReady = useCallback((params) => {
    params.api.forEachNode((node) => node.setSelected(true));
  }, []);

  const onRowDragEnd = (event) => {
    const { node, overNode } = event;
    if (!node || !overNode) return;

    const draggedData = node.data;
    const targetData = overNode.data;

    if (draggedData?.endorsementType === 'PolicyForm' || targetData?.endorsementType === 'PolicyForm') return;
    if (draggedData.id === targetData.id) return;

    setOrdinalsData((prevData) => {
      const newData = [...prevData];

      const draggedIndex = newData.findIndex((entry) => entry.id === draggedData.id);
      const targetIndex = newData.findIndex((entry) => entry.id === targetData.id);

      if (draggedIndex >= 0) {
        newData[draggedIndex] = {
          ...newData[draggedIndex],
          newOrdinal: targetData.ordinal,
        };
      } else {
        newData.push({
          ordinal: draggedData.ordinal,
          newOrdinal: targetData.ordinal,
          formId: draggedData.formId,
          id: draggedData.id,
        });
      }

      if (targetIndex >= 0) {
        newData[targetIndex] = {
          ...newData[targetIndex],
          newOrdinal: draggedData.ordinal,
        };
      } else {
        newData.push({
          ordinal: targetData.ordinal,
          newOrdinal: draggedData.ordinal,
          formId: targetData.formId,
          id: targetData.id,
        });
      }

      return newData;
    });

    const tempOrdinal = draggedData.ordinal;
    draggedData.ordinal = targetData.ordinal;
    targetData.ordinal = tempOrdinal;

    event.api.refreshCells({ force: true });
  };

  return (
    <div style={{ width: '100%', height: '500px' }} className="ag-theme-alpine">
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        rowSelection="multiple"
        onSelectionChanged={onSelectionChanged}
        defaultColDef={defaultColDef}
        onRowDragEnd={onRowDragEnd}
        onGridReady={onGridReady}
      />
      <LayoutBox row>
        <Button
          color="$primary"
          borderRadius={8}
          width="150px"
          marginLeft={8}
          onPress={handleSaveOrder}
          loading={savingOrders}
          disabled={savingOrders}
          style={{ marginTop: '16px' }}
        >
          Save Order
        </Button>
        <Button
          color="$primary"
          borderRadius={8}
          width="150px"
          marginLeft={8}
          onPress={handleDownload}
          loading={isDownloading}
          disabled={isDownloading}
          style={{ marginTop: '16px' }}
        >
          Download
        </Button>
      </LayoutBox>
    </div>
  );
};

export default PolicyGeneration;
