import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '../../../../ui';
import { ViewTabs } from '../../../../components/ViewTabs';
import { Coverages } from '../../../QuotePage/content/QuoteGrids/Coverages';
import { MiscPolicyDetails } from '../../MiscPolicyDetails';
import { MiscValues } from '../../../QuotePage/content/QuoteGrids/MiscValues';
import { MonthlyReporting } from '../MonthlyReporting';
import { PremiumCalculation } from '../../../MCEPage/content/PremiumCalculation';
import { MCEFees } from '../../../MCEPage/content/MCEFees';
import { exportAllGrids } from '../../../../utils/exportAllGrids';
import { isEmpty } from '../../../../ui/utils';
import { Endorsements } from '../../Endorsements';
import { NamedInsured } from '../../NamedInsured';
import { HighValueVehicles } from '../../HighValueVehicles';
import { usePolicyPageContext } from '../../context/PolicyPageContext';
import { useApi } from '../../../../hooks';
import { TemporaryLocations } from '../../TemporaryLocations/TemporaryLocations';
import { LossPayee } from '../../LossPayee/LossPayee';
import { FalsePretense } from '../../FalsePretense';
import { PolicyGeneration } from '../../PolicyGeneration';

export const policyViewTabs = {
  COVERAGES: 'COVERAGES',
  MISC_VALUES: 'MISC_VALUES',
  MCES: 'MCES',
  MONTHLY_REPORTING: 'MONTHLY_REPORTING',
  PREMIUM_CALCULATION: 'PREMIUM_CALCULATION',
  MCE_FEES: 'MCE_FEES',
  ENDORSEMENTS: 'ENDORSEMENTS',
  NAMED_INSURED: 'NAMED_INSURED',
  HIGH_VALUE_VEHICLES: 'HIGH_VALUE_VEHICLES',
  TEMPORARY_LOCATIONS: 'TEMPORARY_LOCATIONS',
  FALSE_PRETENSE: 'FALSE_PRETENSE',
  LOSS_PAYEE: 'LOSS_PAYEE',
  POLICY_GENERATION: 'POLICY_GENERATION',
};
const PolicyGridView = ({
  tab,
  data,
  productCoverages,
  setGridReference,
  onSelectionChanged,
  modalOpen,
  setModalOpen,
  quoteData,
  setQuoteData,
  gridReferences,
  importModal,
  setImportModal,
  handleAddLocation,
  allowDeleteCoverage = false,
  shouldShowWarning,
  setShouldShowWarning,
  setActiveExportFunction,
  setGridReferenceForTab,
  policyTabsList,
  monthlyReportingData,
  MCEsData,
}) => {
  return (
    <div>
      {policyTabsList.includes(policyViewTabs.COVERAGES) && (
        <div style={{ display: tab === policyViewTabs.COVERAGES ? 'block' : 'none' }}>
          <Coverages
            quotes={quoteData}
            productCoverages={productCoverages}
            onSelectionChanged={onSelectionChanged}
            modalOpen={modalOpen}
            setQuoteData={setQuoteData}
            setModalOpen={setModalOpen}
            importModal={importModal}
            gridReference={gridReferences?.COVERAGES}
            setImportModal={setImportModal}
            setExportHandler={(exportFn) => setActiveExportFunction(exportFn, policyViewTabs.COVERAGES)}
            setGridReference={(ref) => setGridReferenceForTab({ tabName: 'COVERAGES', ref })}
            handleAddLocation={handleAddLocation}
            allowDeleteCoverage={allowDeleteCoverage}
            shouldShowWarning={shouldShowWarning}
            setShouldShowWarning={setShouldShowWarning}
          />
        </div>
      )}
      {policyTabsList.includes(policyViewTabs.MCES) && (
        <div style={{ display: tab === policyViewTabs.MCES ? 'block' : 'none' }}>
          <MiscPolicyDetails setGridReference={setGridReference} policy={data} MCEsData={MCEsData} />
        </div>
      )}
      {policyTabsList.includes(policyViewTabs.MISC_VALUES) && (
        <div style={{ display: tab === policyViewTabs.MISC_VALUES ? 'block' : 'none' }}>
          <MiscValues setGridReference={setGridReference} />
        </div>
      )}
      {policyTabsList.includes(policyViewTabs.MONTHLY_REPORTING) && (
        <div style={{ display: tab === policyViewTabs.MONTHLY_REPORTING ? 'block' : 'none' }}>
          <MonthlyReporting setGridReference={setGridReference} policy={data} monthlyReportingData={monthlyReportingData} />
        </div>
      )}
      {policyTabsList.includes(policyViewTabs.PREMIUM_CALCULATION) && (
        <div style={{ display: tab === policyViewTabs.PREMIUM_CALCULATION ? 'block' : 'none' }}>
          <PremiumCalculation
            setGridReference={(ref) => setGridReferenceForTab({ tabName: 'PREMIUM_CALCULATION', ref })}
            policy={data}
            gridReference={gridReferences?.PREMIUM_CALCULATION}
            monthlyReportingData={monthlyReportingData}
            onSelectionChanged={onSelectionChanged}
          />
        </div>
      )}
      {policyTabsList.includes(policyViewTabs.MCE_FEES) && (
        <div style={{ display: tab === policyViewTabs.MCE_FEES ? 'block' : 'none' }}>
          <MCEFees
            policy={data}
            gridReference={gridReferences?.MCE_FEES}
            setGridReference={(ref) => setGridReferenceForTab({ tabName: 'MCE_FEES', ref })}
            onSelectionChanged={onSelectionChanged}
          />
        </div>
      )}
      {policyTabsList.includes(policyViewTabs.ENDORSEMENTS) && (
        <div style={{ display: tab === policyViewTabs.ENDORSEMENTS ? 'block' : 'none' }}>
          <Endorsements setGridReference={(ref) => setGridReferenceForTab({ tabName: 'ENDORSEMENTS', ref })} policy={data} />
        </div>
      )}
      {policyTabsList.includes(policyViewTabs.NAMED_INSURED) && (
        <div style={{ display: tab === policyViewTabs.NAMED_INSURED ? 'block' : 'none' }}>
          <NamedInsured
            setGridReference={(ref) => setGridReferenceForTab({ tabName: 'NAMED_INSURED', ref })}
            policy={data}
            gridReference={gridReferences?.NAMED_INSURED}
            onSelectionChanged={onSelectionChanged}
          />
        </div>
      )}
      {policyTabsList.includes(policyViewTabs.HIGH_VALUE_VEHICLES) && (
        <div style={{ display: tab === policyViewTabs.HIGH_VALUE_VEHICLES ? 'block' : 'none' }}>
          <HighValueVehicles
            setGridReference={(ref) => setGridReferenceForTab({ tabName: 'HIGH_VALUE_VEHICLES', ref })}
            policy={data}
            gridReference={gridReferences?.HIGH_VALUE_VEHICLES}
            onSelectionChanged={onSelectionChanged}
          />
        </div>
      )}
      {policyTabsList.includes(policyViewTabs.TEMPORARY_LOCATIONS) && (
        <div style={{ display: tab === policyViewTabs.TEMPORARY_LOCATIONS ? 'block' : 'none' }}>
          <TemporaryLocations
            setGridReference={(ref) => setGridReferenceForTab({ tabName: 'TEMPORARY_LOCATIONS', ref })}
            policy={data}
            gridReference={gridReferences?.TEMPORARY_LOCATIONS}
            onSelectionChanged={onSelectionChanged}
          />
        </div>
      )}
      {policyTabsList.includes(policyViewTabs.FALSE_PRETENSE) && (
        <div style={{ display: tab === policyViewTabs.FALSE_PRETENSE ? 'block' : 'none' }}>
          <FalsePretense
            setGridReference={(ref) => setGridReferenceForTab({ tabName: 'FALSE_PRETENSE', ref })}
            policy={data}
            gridReference={gridReferences?.FALSE_PRETENSE}
          />
        </div>
      )}
      {policyTabsList.includes(policyViewTabs.LOSS_PAYEE) && (
        <div style={{ display: tab === policyViewTabs.LOSS_PAYEE ? 'block' : 'none' }}>
          <LossPayee
            setGridReference={(ref) => setGridReferenceForTab({ tabName: 'LOSS_PAYEE', ref })}
            policy={data}
            gridReference={gridReferences?.LOSS_PAYEE}
            onSelectionChanged={onSelectionChanged}
          />
        </div>
      )}
      {policyTabsList.includes(policyViewTabs.POLICY_GENERATION) && (
        <div style={{ display: tab === policyViewTabs.POLICY_GENERATION ? 'block' : 'none' }}>
          <PolicyGeneration />
        </div>
      )}
    </div>
  );
};

export const PolicyTabs = React.forwardRef((props, ref) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [gridParams, setGridParams] = useState({});
  const [selectedFields, setSelectedFields] = useState({});
  const [exportHandlers, setExportHandlers] = useState({});
  const [quoteData, setQuoteData] = useState(props.data);
  const { setMCEDetails, MCEDetails, setDeletedLocations, setPremiumData } = props;
  const {
    namedInsureds,
    setNamedInsureds,
    highValueVehicles,
    setHighValueVehicles,
    temporaryFees,
    setTemporaryFees,
    temporaryLocations,
    setTemporaryLocations,
    lossPayee,
    setLossPayee,
  } = usePolicyPageContext();
  const [enableDelete, setEnableDelete] = useState(false);

  const policyTabsKeys = Object.keys(props?.policyTabsList || policyViewTabs);
  const [gridReferences, setGridReferences] = useState({
    COVERAGES: null,
    MCE_FEES: null,
    MONTHLY_REPORTING: null,
    MCES: null,
    ENDORSEMENTS: null,
    HIGH_VALUE_VEHICLES: null,
    TEMPORARY_LOCATIONS: null,
    FALSE_PRETENSE: null,
    LOSS_PAYEE: null,
  });

  const setGridReferenceForTab = ({ tabName, tableName, ref }) => {
    setGridReferences((prevRefs) => ({
      ...prevRefs,
      [tabName]: ref,
    }));
  };

  const handleDeleteMCELocation = async () => {
    try {
      const { premiumData } = props;
      const selectedFields = gridReferences['PREMIUM_CALCULATION'].api.getSelectedRows();

      if (!selectedFields || selectedFields.length === 0) {
        alert('Please select locations to delete.');
        return;
      }

      if (!premiumData || premiumData.length === 0) {
        console.warn('No premiumData available to update.');
        return;
      }

      setDeletedLocations(selectedFields);

      const updatedPremiumData = premiumData.map((row) => {
        const isSelected = selectedFields.some((selectedRow) => selectedRow.locationId === row.locationId);
        return isSelected ? { ...row, isRowDeleted: true, avg_declared_inventory: 0 } : row;
      });
      setPremiumData(updatedPremiumData);
    } catch (error) {
      console.error('Failed to update premium data:', error);
      alert('An error occurred while updating the premium data. Please try again.');
    }
  };

  const handleDeleteInsureds = async () => {
    const selectedFields = gridReferences['NAMED_INSURED'].api.getSelectedRows();

    if (!selectedFields || selectedFields.length === 0) {
      alert('Please select locations to delete.');
      return;
    }
    if (!namedInsureds || namedInsureds.length === 0) {
      console.warn('No namedInsured available to update.');
      return;
    }
    const updatedData = namedInsureds.filter((row) => !selectedFields.some(({ id }) => id === row.id));
    setNamedInsureds(updatedData);
    setEnableDelete(false);
  };

  const handleDeleteLossPayee = async () => {
    const selectedFields = gridReferences['LOSS_PAYEE'].api.getSelectedRows();

    if (!selectedFields || selectedFields.length === 0) {
      alert('Please select data to delete.');
      return;
    }
    if (!lossPayee || lossPayee.length === 0) {
      console.warn('No lossPayee available to update.');
      return;
    }
    const updatedData = lossPayee.filter((row) => !selectedFields.some(({ id }) => id === row.id));
    setLossPayee(updatedData);
    setEnableDelete(false);
  };

  const handleDeleteFee = async (e) => {
    e.preventDefault();
    const fields = gridReferences['MCE_FEES'].api.getSelectedRows();
    if (isEmpty(fields) || !fields) return;
    const result = MCEDetails?.fees.filter((item) => !fields.find((selectedItem) => selectedItem.id === item.id));
    setMCEDetails((pre) => {
      return { ...pre, fees: result };
    });
  };

  const setActiveExportFunction = (exportFn, gridType) => {
    setExportHandlers((prevHandlers) => {
      return {
        ...prevHandlers,
        [gridType]: exportFn,
      };
    });
  };

  const handleDeleteHVV = async () => {
    const selectedFields = gridReferences['HIGH_VALUE_VEHICLES'].api.getSelectedRows();
    if (!selectedFields || selectedFields.length === 0) {
      alert('Please select locations to delete.');
      return;
    }
    if (!highValueVehicles || highValueVehicles.length === 0) {
      console.warn('No Rows available to update.');
      return;
    }
    const updatedData = highValueVehicles.filter((row) => !selectedFields.some(({ id }) => id === row.id));
    console.log('updatedData for the high value vehicels', updatedData);
    setHighValueVehicles(updatedData);
    setEnableDelete(false);
  };

  const handleDeleteTemporaryLocations = async () => {
    const selectedFields = gridReferences['TEMPORARY_LOCATIONS'].api.getSelectedRows();
    if (!selectedFields || selectedFields.length === 0) {
      alert('Please select locations to delete.');
      return;
    }
    if (!temporaryLocations || temporaryLocations.length === 0) {
      console.warn('No Rows available to update.');
      return;
    }
    const updatedData = temporaryLocations.filter((row) => !selectedFields.some(({ locationNumber }) => locationNumber === row.locationNumber));
    console.log('updatedData for the high value vehicels', updatedData);
    setTemporaryLocations(updatedData);
    setEnableDelete(false);
  };
  const setGridReference = (params) => {
    setGridParams(params);
  };

  const onSelectionChanged = useCallback((context) => {
    if (context) {
      const fields = context.api.getSelectedRows();
      if (fields.length) setEnableDelete(true);
    }
  }, []);

  useEffect(() => {
    setQuoteData(props.data);
  }, [props.data]);

  const policyTabsList = Object.keys(props.policyTabsList);
  const initialTab = policyTabsList[0];
  const [viewTab, setViewTab] = useState(initialTab || policyViewTabs.FEES);
  useEffect(() => {
    setModalOpen(false);
  }, [viewTab]);

  const getLabel = (name, label) => {
    if (name === policyViewTabs.MCE_FEES) {
      return 'Fees';
    }
    if (name === policyViewTabs.COVERAGES) {
      return `Coverages`;
    }
    if (name === policyViewTabs.MCES) return 'Misc. Change Endorsements(MCEs)';
    if (name === policyViewTabs.MONTHLY_REPORTING) return 'Monthly Reporting';
    if (name === policyViewTabs.PREMIUM_CALCULATION) return 'Premium Calculation';
    if (name === policyViewTabs.ENDORSEMENTS) return 'Endorsements';
    if (name === policyViewTabs.NAMED_INSURED) return 'Named Insured';
    if (name === policyViewTabs.HIGH_VALUE_VEHICLES) return 'High Value Vehicles';
    if (name === policyViewTabs.TEMPORARY_LOCATIONS) return 'Temporary Locations';
    if (name === policyViewTabs.FALSE_PRETENSE) return 'False Pretense';
    if (name === policyViewTabs.LOSS_PAYEE) return 'Loss Payee';
    if (name === policyViewTabs.POLICY_GENERATION) return 'Policy Generation';
    return label;
  };

  return (
    <>
      <Box
        backgroundColor={'white'}
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        borderBottomColor="#DFE3E8"
        padding={'0px 18px'}
        justifyContent="space-between"
        display="flex"
        paddingLeft={10}
      >
        <Box display="flex" flex={1} flexWrap="wrap" columnGap="24px" rowGap="8px">
          <ViewTabs
            getLabel={getLabel}
            tabs={policyTabsKeys}
            onTabChanged={setViewTab}
            gridReference={gridParams}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            setQuoteData={setQuoteData}
            quotesView={false}
            quoteData={quoteData}
            handleDeleteFee={handleDeleteFee}
            handleDeleteMCELocation={handleDeleteMCELocation}
            handleDeleteInsureds={handleDeleteInsureds}
            handleDeleteLossPayee={handleDeleteLossPayee}
            handleDeleteHVV={handleDeleteHVV}
            handleDeleteTemporaryLocations={handleDeleteTemporaryLocations}
            onExport={() => exportAllGrids({ gridReferences, exportHandlers, workbookName: 'policy' })}
            enableDelete={enableDelete}
            {...props}
          />
        </Box>
      </Box>
      <PolicyGridView
        tab={viewTab}
        {...props}
        setGridReference={setGridReference}
        onSelectionChanged={onSelectionChanged}
        modalOpen={modalOpen}
        gridReferences={gridReferences}
        setModalOpen={setModalOpen}
        setQuoteData={setQuoteData}
        setActiveExportFunction={setActiveExportFunction}
        setGridReferenceForTab={setGridReferenceForTab}
        policyTabsList={policyTabsList}
        quoteData={quoteData}
      />
    </>
  );
});
