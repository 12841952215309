import React, { useEffect, useState } from 'react';
import { Box, Button, Grid } from '../../ui';
import { LayoutBox } from '../../components';
import { useApi } from '../../hooks';
import { currencyFormatter } from '../QuotePage/content/QuoteGrids/utils';

export const FalsePretense = React.forwardRef((props, ref) => {
  const { policy, setGridReference, gridReference } = props;
  const { createEndorsement, downloadEndorsement } = useApi();
  const [rowData, setRowData] = useState();
  const [locationData, setLocationData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    setRowData(policy?.falsePretense);
  }, [policy]);

  useEffect(() => {
    const locations = policy?.locations;
    const mappedLocationData = locations.map((location) => {
      const autoDeductible = location.coverages.find((coverage) => coverage.coverageType === 'falsePretense')?.deductible || null;

      return {
        address: location.address,
        autoDeductible,
        lossDeductible: 0,
      };
    });

    setLocationData(mappedLocationData);
  }, [policy]);

  const columnDefs = [
    {
      headerName: 'Location Per Occurrence',
      field: 'falsePretensePerLocPerOcc',
      editable: true,
      valueFormatter: (field) => currencyFormatter({ field, withDecimals: true }),
    },
    {
      headerName: 'Limit of Insurance',
      field: 'falsePretensePolicyLimit',
      editable: true,
      valueFormatter: (field) => currencyFormatter({ field, withDecimals: true }),
    },
  ];

  const locationColumns = [
    {
      headerName: 'Location Address',
      field: 'address',
      editable: false,
    },
    {
      headerName: 'Per Auto Deductible',
      field: 'autoDeductible',
      editable: false,
      valueFormatter: (field) => {
        const value = field.value;
        return value === 0 || isNaN(value) ? 'N/A' : currencyFormatter({ field });
      },
    },
    {
      headerName: 'Per Loss Deductible',
      field: 'lossDeductible',
      editable: false,
      valueFormatter: (field) => {
        const value = field.value;
        return value === 0 || isNaN(value) ? 'N/A' : currencyFormatter({ field });
      },
    },
  ];

  const payload = {
    endorsementType: 'falsePretense',
    endorsementData: {
      falsePretense: rowData,
    },
  };

  const handleOnSave = async () => {
    setIsLoading(true);
    try {
      const response = await createEndorsement(policy.id, payload);
      if (response) {
        const downloadUrl = response.value;
        window.open(downloadUrl, '_blank');
      }
    } catch (error) {
      console.error('Error saving payload:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnDownload = async () => {
    setIsDownloading(true);
    try {
      const response = await downloadEndorsement(policy.id, payload);
      if (response) {
        const downloadUrl = response.data.value;
        window.open(downloadUrl, '_blank');
      }
    } catch (error) {
      console.error('Error saving payload:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Box>
      <div className="ag-theme-alpine" style={{ height: '60px', width: '100%', marginBottom: '20px' }}>
        <Grid
          data={[rowData]}
          columnDefs={columnDefs}
          defaultColDef={{
            flex: 1,
            resizable: true,
          }}
          setGridReference={setGridReference}
          onCellValueChanged={(params) => {
            const updatedData = {
              ...rowData,
              [params.colDef.field]: params.data[params.colDef.field],
            };
            setRowData(updatedData);
          }}
          floatingFilter={false}
          shouldShowPagination={false}
        />
      </div>

      <div className="ag-theme-quartz" style={{ maxHeight: '600px', overflowY: 'auto' }}>
        <Grid
          data={locationData}
          columnDefs={locationColumns}
          defaultColDef={{
            flex: 1,
            resizable: true,
          }}
          setGridReference={setGridReference}
          floatingFilter={false}
          shouldShowPagination={false}
        />
      </div>

      <LayoutBox row justifyContent="flex-start" alignItems="center" flexWrap="wrap" gap={8}>
        <Box
          border="1px solid #919EAB52"
          borderRadius={8}
          margin="$2"
          style={{
            flex: '0 0 auto',
          }}
          minWidth="10%"
        >
          <Button
            onPress={handleOnSave}
            label={isLoading ? '' : 'Save'}
            paddingX={16}
            minHeight={40}
            minWidth={120}
            loading={isLoading}
            disabled={isLoading}
            color="$primary"
            variant="text"
          />
        </Box>

        <Box
          border="1px solid #919EAB52"
          borderRadius={8}
          margin="$2"
          style={{
            flex: '0 0 auto',
          }}
        >
          <Button
            onPress={handleOnDownload}
            label={isDownloading ? '' : 'Download'}
            paddingX={16}
            minHeight={40}
            minWidth={120}
            loading={isDownloading}
            disabled={isDownloading}
            color="$primary"
            variant="text"
          />
        </Box>
      </LayoutBox>
    </Box>
  );
});
