import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Text } from '../../../ui';
import { LayoutBox } from '../../../components';
import { useApi } from '../../../hooks';
import { currencyFormatter } from '../../QuotePage/content/QuoteGrids/utils';
import moment from 'moment-timezone';
import { usePolicyPageContext } from '../context/PolicyPageContext';

export const LenderLossPayee = React.forwardRef((props, ref) => {
  const { policy, setLenderLossPayeeRef } = props;
  const { createEndorsement, downloadEndorsement } = useApi();
  const { endorsements = [] } = usePolicyPageContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const policyEffectiveDate = moment.utc(policy.effectiveAt).format('YYYY-MM-DD');

  const [endorsementData, setEndorsementData] = useState({
    lienHolder: '',
    lienHolderAddress: '',
    lienHolderCityStateZip: '',
    lienHolderLocations: '',
    premium: 0,
  });
  const [ordinal, setOrdinal] = useState();

  useEffect(() => {
    if (!!endorsements.length) {
      const lenderLossPayee = endorsements?.find(({ endorsementType }) => endorsementType === 'lenderLossPayee');
      lenderLossPayee?.endorsementData && setEndorsementData(lenderLossPayee?.endorsementData);
      lenderLossPayee?.ordinal && setOrdinal(lenderLossPayee?.ordinal);
    }
  }, [endorsements]);

  const lendorLossPayeeColumns = [
    {
      headerName: 'Lender Loss Payee',
      field: 'lienHolder',
      editable: true,
    },
    {
      headerName: 'Address',
      field: 'lienHolderAddress',
      editable: true,
    },
    {
      headerName: 'City, State Zip',
      field: 'lienHolderCityStateZip',
      editable: true,
    },
    {
      headerName: 'Locations',
      field: 'lienHolderLocations',
      editable: true,
    },
    {
      headerName: 'Premium',
      field: 'premium',
      editable: true,
      valueFormatter: (field) => currencyFormatter({ field, withDecimals: true }),
    },
  ];

  const payload = {
    endorsementType: 'lenderLossPayee',
    endorsementData: {
      policyEffectiveDate,
      ...endorsementData,
    },
    ordinal,
  };

  const handleOnSave = async () => {
    setIsLoading(true);
    try {
      const response = await createEndorsement(policy.id, payload);
      if (response) {
        const downloadUrl = response.value;
        window.open(downloadUrl, '_blank');
      }
    } catch (error) {
      console.error('Error saving payload:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnDownload = async () => {
    setIsDownloading(true);
    try {
      const response = await downloadEndorsement(policy.id, payload);
      if (response) {
        const downloadUrl = response.data.value;
        window.open(downloadUrl, '_blank');
      }
    } catch (error) {
      console.error('Error saving payload:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <LayoutBox>
      <Box marginTop="25px" margin="10px">
        <Text bold large>
          Lender Loss Payee
        </Text>
      </Box>

      <div className="ag-theme-quartz" style={{ maxHeight: '600px', overflowY: 'auto' }}>
        <Grid
          data={[endorsementData]}
          columnDefs={lendorLossPayeeColumns}
          defaultColDef={{
            flex: 1,
            resizable: true,
          }}
          setGridReference={setLenderLossPayeeRef}
          floatingFilter={false}
          shouldShowPagination={false}
          onCellValueChanged={(params) => {
            const updatedData = {
              ...endorsementData,
              [params.colDef.field]: params.data[params.colDef.field],
            };
            setEndorsementData(updatedData);
          }}
        />
      </div>
      <LayoutBox row justifyContent="flex-start" alignItems="center" flexWrap="wrap" gap={8}>
        <Box
          border="1px solid #919EAB52"
          borderRadius={8}
          margin="$2"
          style={{
            flex: '0 0 auto',
          }}
          minWidth="10%"
        >
          <Button
            onPress={handleOnSave}
            label={isLoading ? '' : 'Save'}
            paddingX={16}
            minHeight={40}
            minWidth={120}
            loading={isLoading}
            disabled={isLoading}
            color="$primary"
            variant="text"
          />
        </Box>

        <Box
          border="1px solid #919EAB52"
          borderRadius={8}
          margin="$2"
          style={{
            flex: '0 0 auto',
          }}
        >
          <Button
            onPress={handleOnDownload}
            label={isDownloading ? '' : 'Download'}
            paddingX={16}
            minHeight={40}
            minWidth={120}
            loading={isDownloading}
            disabled={isDownloading}
            color="$primary"
            variant="text"
          />
        </Box>
      </LayoutBox>
    </LayoutBox>
  );
});
