import React, { useCallback, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Theme
import 'ag-grid-enterprise';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { ActivityIndicator } from '../ActivityIndicator';

export const Grid = (props) => {
  const onGridReady = (params) => {
    props.setGridReference(params);
    params.api.sizeColumnsToFit();
  };
  const { data, columns, gridOptions, pageSize = 10, floatingFilter = true, shouldShowPagination = true, loading } = props;

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
      filter: true,
      suppressHeaderMenuButton: true,
      filterParams: {
        buttons: ['reset', 'apply'],
        suppressMiniFilter: true,
      },
      floatingFilter: floatingFilter,
      enableCellChangeFlash: true,
    };
  }, [floatingFilter]);

  const paginationPageSizeSelector = useMemo(() => {
    return [10, 20, 50, 100];
  }, []);

  const processCellFromClipboard = useCallback((params) => {
    const { dataType } = params.column.colDef;

    if (dataType === 'number') return Number(params.value.replace(/[^0-9.]/g, ''));
    else if (params?.node?.data?.type === 'monthlyInventory') {
      const value = params.value;
      if (/\d/.test(value)) {
        return value.replace(/[^0-9.]/g, '');
      }
      return value;
    } else return params.value;
  }, []);

  const processCellForClipboard = useCallback((params) => {
    const { dataType } = params.column.colDef;
    const value = params.value;

    if (dataType === 'number' || params?.node?.data?.type === 'monthlyInventory') {
      return value ? value.toLocaleString()?.replace(/[$,]/g, '') : '';
    }
    return value;
  }, []);

  return (
    <div id="ag-grid-table" className="ag-theme-alpine" style={{ height: !data.length && '120px', marginBottom: '10px' }}>
      <AgGridReact
        rowData={data}
        columnDefs={columns}
        headerHeight="36"
        defaultColDef={defaultColDef}
        loading={loading}
        {...props}
        onGridReady={onGridReady}
        pagination={!!data.length && shouldShowPagination}
        paginationPageSize={pageSize}
        paginationPageSizeSelector={paginationPageSizeSelector}
        modules={[ExcelExportModule]}
        overlayNoRowsTemplate={'<div>No rows to show</div>'}
        suppressPropertyNamesCheck={true}
        gridOptions={gridOptions}
        suppressCellSelection={true}
        enableRangeSelection={true}
        reactiveCustomComponents
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={100}
        suppressRowClickSelection={true}
        processCellFromClipboard={processCellFromClipboard}
        processCellForClipboard={processCellForClipboard}
        suppressBrowserResizeObserver
        loadingOverlayComponent="customSpinner"
        components={{
          customSpinner: ActivityIndicator,
        }}
      />
    </div>
  );
};
