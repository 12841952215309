import React, { useEffect, useState } from 'react';
import { Box, Button, Grid } from '../../../ui';
import { LayoutBox } from '../../../components';
import { useApi } from '../../../hooks';
import { uniqueId } from '../../../ui/utils';
import { usePolicyPageContext } from '../context/PolicyPageContext';
import { LenderLossPayee } from './LenderLossPayee';

export const LossPayee = React.forwardRef((props, ref) => {
  const { policy, setGridReference, gridReference, onSelectionChanged } = props;
  const { createEndorsement } = useApi();
  const { lossPayee, setLossPayee } = usePolicyPageContext();
  const [isLoading, setIsLoading] = useState(false);
  const [lenderLossPayeeRef, setLenderLossPayeeRef] = useState();

  const lossPayeeColumn = [
    {
      headerName: 'Loss Payee',
      field: 'lossPayee',
      editable: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      valueSetter: (params) => {
        const { newValue, data } = params;
        data.lossPayee = newValue;
        setLossPayee((prevData) => {
          return prevData.map((row) => (row.id === data.id ? { ...row, lossPayee: newValue } : row));
        });
        return true;
      },
    },
  ];

  useEffect(() => {
    policy.lossPayee && policy.lossPayee?.length && setLossPayee(policy.lossPayee?.map((lossPayee) => ({ id: uniqueId(), lossPayee: lossPayee })));
  }, [policy.lossPayee]);

  const handleAddRow = () => {
    const newRow = { id: uniqueId(), lossPayee: '' };
    setLossPayee((prevData) => (Array.isArray(prevData) ? [...prevData, newRow] : [newRow]));
  };

  const payload = {
    endorsementType: 'lossPayee',
    endorsementData: {
      lossPayee: lossPayee.map(({ lossPayee }) => lossPayee).filter((value) => value && value.trim() !== ''),
    },
  };

  const handleOnSave = async () => {
    setIsLoading(true);
    try {
      await createEndorsement(policy.id, payload);
      alert('Loss Payee Updated Successfully.');
    } catch (error) {
      console.error('Error updating loss payee:', error);
      alert('An error occurred while updating loss payee. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Box>
        <div className="ag-theme-alpine" style={{ maxHeight: '600px', overflowY: 'auto' }}>
          <Grid
            data={lossPayee}
            columnDefs={lossPayeeColumn}
            setGridReference={setGridReference}
            rowSelection="multiple"
            shouldShowPagination={false}
            columnWidth={150}
            enableRangeSelection={true}
            floatingFilter={false}
            onSelectionChanged={onSelectionChanged}
          />
        </div>
        <LayoutBox row justifyContent="flex-start" alignItems="center" flexWrap="wrap" gap={8}>
          <Box
            border="1px solid #919EAB52"
            borderRadius={8}
            margin="$2"
            style={{
              flex: '0 0 auto',
            }}
          >
            <Button color="#5F2994" variant="text" label="Add Payee" paddingX={16} paddingY={8} minHeight={40} onPress={handleAddRow} />
          </Box>

          <Box
            border="1px solid #919EAB52"
            borderRadius={8}
            margin="$2"
            style={{
              flex: '0 0 auto',
            }}
            minWidth="10%"
          >
            <Button
              onPress={handleOnSave}
              label={isLoading ? '' : 'Save'}
              paddingX={16}
              paddingY={8}
              minHeight={40}
              loading={isLoading}
              disabled={isLoading}
              variant="text"
              color="$primary"
            />
          </Box>
        </LayoutBox>
      </Box>

      <LenderLossPayee policy={policy} setLenderLossPayeeRef={setLenderLossPayeeRef} lenderLossPayeeRef={lenderLossPayeeRef} />
    </Box>
  );
});
