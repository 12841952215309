import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, Text } from '../../../ui';
import { LayoutBox } from '../../../components';
import { currencyFormatter } from '../../QuotePage/content/QuoteGrids/utils';
import { formatAddress } from '../../../utils/getFormattedAddress';
import { apiHelpers } from '../../../utils';
import { useApi } from '../../../hooks';
import { TemporaryLocationsFees } from './TemporaryLocationsFees';
import { usePolicyPageContext } from '../context/PolicyPageContext';
import { uniqueId } from '../../../ui/utils';

export const TemporaryLocations = React.forwardRef((props, ref) => {
  const { policy, setGridReference, gridReference } = props;

  const [isLoading, setIsLoading] = useState(false);
  const { insertMultipleEndorsements, downloadEndorsement, getEndorsements } = useApi();
  const [feeGridRef, setFeeGridRef] = useState();
  const [endorsementGroups, setEndorsementGroups] = useState([]);
  const [downloadingTableId, setDownloadingTableId] = useState(null);
  const [deletedRows, setDeletedRows] = useState({});
  const TEMPORARY_LOCATIONS = 'temporaryLocations';

  const addNewEndorsementGroup = () => {
    setEndorsementGroups((prev) => {
      let lastEndorsement = prev.length ? prev[prev.length - 1] : null;
      if (!lastEndorsement) {
        const temporaryLocations = endorsements?.find(({ endorsementType }) => endorsementType === TEMPORARY_LOCATIONS);
        const formattedData = temporaryLocations ? transformGroupData(temporaryLocations) : [];
        lastEndorsement = formattedData.length ? formattedData[0] : null;
      }
      const premium = lastEndorsement ? lastEndorsement.endorsements?.premium : policy?.netPremium;
      const lastLocations = Array.isArray(lastEndorsement?.locations) ? lastEndorsement.locations : [];
      const lastLocationNumber = lastLocations.length
        ? Math.max(...lastLocations.map((loc) => parseInt(loc.locationNumber?.replace('T', '') || 0, 10)))
        : 0;

      const newLocations = lastLocations.map((loc, index) => ({
        ...loc,
        locationNumber: `T${lastLocationNumber + index + 1}`,
      }));
      let newEndorsement = {
        ...lastEndorsement,
        id: uniqueId(),
        endorsements: {
          premium,
        },
        locations: newLocations,
      };
      delete newEndorsement.ordinal;
      return [...prev, { ...newEndorsement }];
    });
  };
  const { endorsements = [], setTemporaryFees, setEndorsements } = usePolicyPageContext();
  const transformGroupData = (data) => {
    const updatedData = Array.isArray(data) ? data : [data];
    return updatedData?.map(({ endorsementData, rows, ordinal }) => ({
      id: uniqueId(),
      endorsements: {
        premium: endorsementData?.premium || policy.netPremium,
        effectiveAt: endorsementData?.effectiveAt,
        effectiveUntil: endorsementData?.effectiveUntil,
      },
      locations: rows || [],
      fees: endorsementData.fees || policy?.fees || [],
      ordinal,
    }));
  };

  useEffect(() => {
    const temporaryLocations = endorsements?.filter(({ endorsementType }) => endorsementType === TEMPORARY_LOCATIONS);
    const formattedData = temporaryLocations ? transformGroupData(temporaryLocations) : [];
    setEndorsementGroups(formattedData);
  }, [endorsements]);

  const handleFeeUpdate = (updatedFees) => {
    setTemporaryFees(updatedFees);
  };

  const getDefaultCoverages = (policy, data) => {
    const allCoverages = [
      { coverageType: 'comprehensive', coverageId: 6 },
      { coverageType: 'flood', coverageId: 21 },
      { coverageType: 'earthquake', coverageId: 22 },
      { coverageType: 'hail', coverageId: 9 },
      { coverageType: 'collision', coverageId: 7 },
      { coverageType: 'terrorism', coverageId: 11 },
    ];

    const previousCoverages = policy.locations.length > 0 ? policy.locations[0].coverages : [];

    const updatedCoverages = allCoverages.map((coverage) => {
      const previousCoverage = previousCoverages.find((prev) => prev.coverageId === coverage.coverageId);

      return {
        ...coverage,
        payoutLimit: 100000,
        deductible: previousCoverage?.deductible || 0,
        aggregate: previousCoverage?.aggregate || 0,
      };
    });

    return updatedCoverages;
  };

  const getColumnsDef = (id) => {
    const endorsementIndex = endorsementGroups.findIndex((e) => e.id === id);
    if (endorsementIndex === -1) return;
    const selectedEndorsement = { ...endorsementGroups[endorsementIndex] };

    const getLocationIndex = (locationNumber) => selectedEndorsement.locations.findIndex((location) => location.locationNumber === locationNumber);
    const getColValues = (params, coverageType, key) => {
      const { data } = params;
      const coverage = data.coverages && data.coverages.length && data.coverages.find((each) => each.coverageType === coverageType);
      if (coverage) {
        let value = coverage[key];
        return value;
      }
      return '';
    };

    const setGridValues = (params) => {
      const { data, newValue } = params;
      const locationNumber = params.data.locationNumber;
      const locationIndex = getLocationIndex(locationNumber);
      let coverageId = '';
      let editedField = '';
      coverageId = params.column.userProvidedColDef.coverageId;
      editedField = params.colDef.field;
      const coverageIndex = data.coverages.findIndex((coverage) => coverage.coverageId === coverageId);
      let formattedValue = 0;
      formattedValue = isNaN(Number(newValue)) ? 0 : Number(newValue);

      let updatedRowData = [...selectedEndorsement.locations];
      if (locationIndex !== -1) {
        updatedRowData[locationIndex] = {
          ...updatedRowData[locationIndex],
          coverages: updatedRowData[locationIndex].coverages.map((coverage, idx) => {
            if (idx === coverageIndex) {
              return {
                ...coverage,
                [editedField]: formattedValue,
              };
            }
            return coverage;
          }),
        };
      }
      if (
        editedField === 'payoutLimit' &&
        updatedRowData[locationIndex].coverages.some((covg) => covg.coverageType === 'comprehensive' && covg.coverageId === coverageId)
      ) {
        updatedRowData[locationIndex] = {
          ...updatedRowData[locationIndex],
          coverages: updatedRowData[locationIndex].coverages.map((coverage) => {
            if (coverage.coverageType !== 'comprehensive') {
              return {
                ...coverage,
                payoutLimit: formattedValue,
              };
            }
            return coverage;
          }),
        };
      }
      const updatedGroup = endorsementGroups.map((t) =>
        t.id === id
          ? {
              ...t,
              locations: updatedRowData,
            }
          : t
      );
      setEndorsementGroups(updatedGroup);
      return true;
    };

    const generateCoverageColumns = () => {
      const createCoverageColumns = (coverage) => {
        const { coverageType, coverageId } = coverage;
        const shortName = apiHelpers.getShortCoveragesName(coverageType);
        const coverageColumns = [
          {
            headerName: `${shortName} Per Car Deductible`,
            field: 'deductible',
            dataType: 'number',
            valueGetter: (params) => getColValues(params, coverageType, 'deductible'),
            valueFormatter: (field) => currencyFormatter({ field, withDecimals: true }),
            editable: true,
            valueSetter: (params) => setGridValues(params),
            coverageId,
            minWidth: 200,
          },
          {
            headerName: `${shortName} Agg Deductible`,
            field: 'aggregate',
            dataType: 'number',
            valueGetter: (params) => getColValues(params, coverageType, 'aggregate'),
            valueFormatter: (field) => {
              const value = field.value;
              return value === 0 || isNaN(value) ? 'N/A' : currencyFormatter({ field });
            },
            editable: true,
            valueSetter: (params) => setGridValues(params),
            coverageId,
            minWidth: 200,
          },
          {
            headerName: `${shortName} Payout Limit`,
            field: 'payoutLimit',
            dataType: 'number',
            valueFormatter: (field) => currencyFormatter({ field, withDecimals: true }),
            valueGetter: (params) => getColValues(params, coverageType, 'payoutLimit'),
            editable: true,
            valueSetter: (params) => setGridValues(params),
            coverageId,
            minWidth: 200,
          },
        ];
        return coverageColumns;
      };
      return getDefaultCoverages(policy).map(createCoverageColumns);
    };

    return [
      {
        headerName: 'Location',
        field: 'locationNumber',
        dataType: 'number',
        pinned: true,
        maxWidth: 110,
        editable: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {
        headerName: 'Name',
        field: 'locationName',
        dataType: 'string',
        pinned: true,
        editable: true,
      },
      {
        headerName: 'Address',
        field: 'locationAddress',
        dataType: 'string',
        pinned: true,
        minWidth: 250,
        editable: true,
        valueGetter: (params) => {
          const visibleAddress = formatAddress(params.data.visibleAddress) || '';
          const locationAddress = formatAddress(params.data.locationAddress) || '';
          if (visibleAddress === locationAddress) {
            return visibleAddress || locationAddress;
          }
          return visibleAddress && locationAddress ? `${visibleAddress}, ${locationAddress}` : visibleAddress || locationAddress;
        },
      },
      ...generateCoverageColumns().flat(),
    ];
  };

  const handleAddRow = (endorsementGroupId) => {
    setEndorsementGroups((prevEndorsements) => {
      const endorsementIndex = prevEndorsements.findIndex((e) => e.id === endorsementGroupId);
      if (endorsementIndex === -1) return prevEndorsements;

      const updatedEndorsement = { ...prevEndorsements[endorsementIndex] };
      const maxLocationNumber = Math.max(...updatedEndorsement.locations?.map((row) => parseInt(row.locationNumber.replace('T', ''))), 0);
      const nextNumber = maxLocationNumber + 1;
      const defaultCoverages = getDefaultCoverages(policy);

      const newRow = {
        locationNumber: `T${nextNumber}`,
        coverages: defaultCoverages,
      };

      updatedEndorsement.locations = [...updatedEndorsement?.locations, newRow];

      gridReference.current?.api?.applyTransaction({ add: [newRow] });

      const updatedEndorsements = [...prevEndorsements];
      updatedEndorsements[endorsementIndex] = updatedEndorsement;

      return updatedEndorsements;
    });
  };

  const updateEndorsementField = (endorsementId, key, value) => {
    setEndorsementGroups((prevEndorsements) =>
      prevEndorsements.map((endorsement) =>
        endorsement.id === endorsementId
          ? {
              ...endorsement,
              endorsements: {
                ...endorsement.endorsements,
                [key]: value,
              },
            }
          : endorsement
      )
    );
  };

  const headerColumns = (id) => [
    {
      headerName: 'Effective At',
      field: 'effectiveAt',
      dataType: 'string',
      editable: true,
      cellEditorSelector: () => {
        return {
          component: 'agDateCellEditor',
          params: {
            dateFormat: 'YYYY-MM-DD',
          },
        };
      },
      valueSetter: (params) => {
        let dateValue;
        if (typeof params.newValue === 'string') {
          dateValue = new Date(params.newValue);
        } else if (params.newValue instanceof Date) {
          dateValue = params.newValue;
        } else {
          return false;
        }
        if (!isNaN(dateValue.getTime())) {
          const year = dateValue.getFullYear();
          const month = String(dateValue.getMonth() + 1).padStart(2, '0');
          const day = String(dateValue.getDate()).padStart(2, '0');
          params.data.effectiveAt = `${year}-${month}-${day}`;
          updateEndorsementField(id, 'effectiveAt', `${year}-${month}-${day}`);
        }
      },
      cellDataType: 'string',
    },

    {
      headerName: 'Effective Until',
      headerClass: 'grid-cell-centered',
      field: 'effectiveUntil',
      dataType: 'string',
      editable: true,
      cellEditorSelector: () => {
        return {
          component: 'agDateCellEditor',
          params: {
            dateFormat: 'YYYY-MM-DD',
          },
        };
      },
      valueSetter: (params) => {
        let dateValue;
        if (typeof params.newValue === 'string') {
          dateValue = new Date(params.newValue);
        } else if (params.newValue instanceof Date) {
          dateValue = params.newValue;
        } else {
          return false;
        }
        if (!isNaN(dateValue.getTime())) {
          const year = dateValue.getFullYear();
          const month = String(dateValue.getMonth() + 1).padStart(2, '0');
          const day = String(dateValue.getDate()).padStart(2, '0');
          params.data.effectiveUntil = `${year}-${month}-${day}`;
          updateEndorsementField(id, 'effectiveUntil', `${year}-${month}-${day}`);
        }
      },
      cellDataType: 'string',
    },

    {
      headerName: 'Premium',
      field: 'premium',
      editable: true,
      cellStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      },
      valueSetter: (params) => {
        updateEndorsementField(id, 'premium', params.newValue);
      },
      valueFormatter: (field) => currencyFormatter({ field, withDecimals: true }),
    },
  ];

  const handleOnSave = async () => {
    setIsLoading(true);

    try {
      const updatedEndorsements = endorsementGroups.map((group) => ({
        endorsementType: TEMPORARY_LOCATIONS,
        endorsementData: {
          premium: group.endorsements?.premium || 0,
          effectiveAt: group.endorsements?.effectiveAt || null,
          effectiveUntil: group.endorsements?.effectiveUntil || null,
          fees: group.fees || [],
        },
        ordinal: group.ordinal,
        rows: (group.locations || [])
          .filter((row) =>
            Object.entries(row).some(([key, value]) => {
              if (key === 'id') return false;
              return value !== '' && value !== null && value !== undefined && !(typeof value === 'number' && value === 0);
            })
          )
          .map((row) => ({
            locationNumber: row.locationNumber,
            locationName: row.locationName,
            locationAddress: row.locationAddress,
            coverages: row.coverages,
          })),
      }));

      const payload = {
        endorsements: updatedEndorsements,
      };
      const response = await insertMultipleEndorsements(policy.id, payload, TEMPORARY_LOCATIONS);
      if (response) {
        const endorsement = await getEndorsements(policy.id);
        setEndorsements(endorsement);
      }
      alert('Saved Successfully.');
    } catch (error) {
      console.error('Error saving endorsements:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnDownload = async (endorsementGroup) => {
    if (!endorsementGroup || !endorsementGroup.id) {
      console.error('Invalid endorsement group selected.');
      return;
    }

    setDownloadingTableId(endorsementGroup.id);

    try {
      const payload = {
        endorsementType: TEMPORARY_LOCATIONS,
        endorsementData: {
          premium: endorsementGroup.endorsements?.premium || 0,
          effectiveAt: endorsementGroup.endorsements?.effectiveAt || null,
          effectiveUntil: endorsementGroup.endorsements?.effectiveUntil || null,
          fees: endorsementGroup.fees || [],
        },
        ordinal: endorsementGroup.ordinal,
        rows: (endorsementGroup.locations || [])
          .filter((row) =>
            Object.entries(row).some(([key, value]) => {
              if (key === 'id') return false;
              return value !== '' && value !== null && value !== undefined && !(typeof value === 'number' && value === 0);
            })
          )
          .map((row) => ({
            locationNumber: row.locationNumber,
            locationName: row.locationName,
            locationAddress: row.locationAddress,
            coverages: row.coverages,
          })),
      };

      const response = await downloadEndorsement(policy.id, payload);
      if (response?.data?.value) {
        window.open(response.data.value, '_blank');
      }
    } catch (error) {
      console.error('Error downloading endorsement:', error);
    } finally {
      setDownloadingTableId(null);
    }
  };

  const handleCellValueChanged = useCallback((params, endorsementGroupId) => {
    const { data, colDef, newValue } = params;

    setEndorsementGroups((prevEndorsements) => {
      return prevEndorsements.map((endorsement) => {
        if (endorsement.id !== endorsementGroupId) return endorsement;
        const updatedEndorsement = { ...endorsement };
        updatedEndorsement.locations = endorsement.locations.map((row) =>
          row.locationNumber === data.locationNumber ? { ...row, [colDef.field]: newValue } : row
        );

        return updatedEndorsement;
      });
    });
  }, []);

  const handleDeleteRows = (tableId) => {
    const rowsToDelete = deletedRows[tableId] || [];
    if (!rowsToDelete.length) {
      alert('Please select rows to delete.');
      return;
    }

    setEndorsementGroups((prevTables) =>
      prevTables.map((table) => {
        if (table.id === tableId) {
          const updatedHighValueVehicles = table.locations.filter(
            (row) => !rowsToDelete.some((deletedRow) => deletedRow.locationName === row.locationName)
          );

          return { ...table, locations: updatedHighValueVehicles };
        }
        return table;
      })
    );

    setDeletedRows((prevState) => {
      const newState = { ...prevState };
      delete newState[tableId];
      return newState;
    });
  };

  const onSelectionChanged = useCallback((context, id) => {
    if (context) {
      const selectedRows = context.api.getSelectedRows();
      setDeletedRows((prevState) => ({
        ...prevState,
        [id]: selectedRows,
      }));
    }
  }, []);

  return (
    <Box>
      {endorsementGroups.map((group, index) => (
        <Box
          key={group.id}
          style={{
            padding: '16px',
            border: '1px solid #ddd',
            borderRadius: '8px',
            margin: '10px',
            background: '#f9f9f9',
          }}
        >
          <input
            type="text"
            defaultValue={`Temp Locations_${index + 1}`}
            value={group?.notes}
            onChange={(e) => {
              const updatedTables = endorsementGroups.map((t) => (t.id === group.id ? { ...t, notes: e.target.value } : t));
              setEndorsementGroups(updatedTables);
            }}
            placeholder="Add a note..."
            style={{
              width: '100%',
              padding: '10px',
              fontSize: '14px',
              fontWeight: 'bold',
              border: '1px solid #ccc',
              borderRadius: '6px',
              background: '#fff',
              outline: 'none',
              transition: 'border-color 0.2s ease-in-out',
              marginBottom: '10px',
            }}
            onFocus={(e) => (e.target.style.borderColor = '#007bff')}
            onBlur={(e) => (e.target.style.borderColor = '#ccc')}
          />
          {/* Endorsements Grid */}
          <div className="ag-theme-alpine" style={{ height: '60px', width: '100%', marginBottom: '20px' }}>
            <Grid
              data={Array.isArray(group.endorsements) ? group.endorsements : [group.endorsements]}
              columnDefs={headerColumns(group.id)}
              defaultColDef={{ flex: 1, resizable: true }}
              floatingFilter={false}
              shouldShowPagination={false}
              setGridReference={setGridReference}
            />
          </div>
          {/* Locations Grid */}
          <div className="ag-theme-quartz" style={{ maxHeight: '600px', overflowY: 'auto', minHeight: '200px' }}>
            <Grid
              data={group.locations}
              columns={getColumnsDef(group.id)}
              domLayout="autoHeight"
              rowSelection="multiple"
              floatingFilter={false}
              shouldShowPagination={false}
              enableRangeSelection={true}
              setGridReference={setGridReference}
              onCellValueChanged={(params) => handleCellValueChanged(params, group.id)}
              enableFillHandle={true}
              onSelectionChanged={(context) => onSelectionChanged(context, group.id)}
            />
          </div>
          <LayoutBox row justifyContent="flex-start" alignItems="center" flexWrap="wrap" gap={6}>
            <Box border="1px solid #919EAB52" borderRadius={8} margin="$1">
              <Button
                label="Add Location"
                color="$primary"
                variant="text"
                size="small"
                minWidth={100}
                minHeight={28}
                onPress={() => handleAddRow(group.id)}
              />
            </Box>

            <Box border="1px solid #919EAB52" borderRadius={8} margin="$1">
              <Button
                label={downloadingTableId === group.id ? '' : 'Download'}
                color="$primary"
                variant="text"
                size="small"
                minWidth={80}
                minHeight={28}
                loading={downloadingTableId === group.id}
                onPress={() => handleOnDownload(group)}
                disabled={downloadingTableId === group.id}
              />
            </Box>
            <Box border="1px solid #e74c3c" borderRadius={8} margin="$1">
              <Button
                label="Delete Location(s)"
                color="red"
                variant="text"
                size="small"
                minWidth={80}
                minHeight={28}
                onPress={() => {
                  handleDeleteRows(group.id);
                }}
              />
            </Box>
            <Box border="1px solid #e74c3c" borderRadius={8} margin="$1">
              <Button
                label="Delete Table"
                color="red"
                variant="text"
                size="small"
                minWidth={80}
                minHeight={28}
                onPress={() => {
                  setEndorsementGroups(endorsementGroups.filter((t) => t.id !== group.id));
                }}
              />
            </Box>
          </LayoutBox>
          {/* Fees Grid */}
          <LayoutBox>
            <Box margin="10px">
              <Text bold large>
                Fees
              </Text>
            </Box>
            <TemporaryLocationsFees
              policy={{ ...policy, fees: group.fees }}
              setFeeGridRef={setFeeGridRef}
              onFeeUpdate={(updatedFees) => handleFeeUpdate(updatedFees, index)}
              feeGridRef={feeGridRef}
              onSelectionChanged={onSelectionChanged}
              endorsementData={group.endorsements}
              setGridReference={setGridReference}
            />
          </LayoutBox>
        </Box>
      ))}
      <LayoutBox row gap={8}>
        <Box border="1px solid #919EAB52" borderRadius={8} margin="$2" width="250px">
          <Button label="Add New Endorsement Group" color="$primary" paddingX={16} minHeight={40} minWidth={120} onPress={addNewEndorsementGroup} />
        </Box>
        <Box border="1px solid #919EAB52" borderRadius={8} margin="$2">
          <Button
            label={isLoading ? '' : 'Save'}
            color="$primary"
            variant="text"
            paddingX={16}
            minHeight={40}
            minWidth={120}
            onPress={handleOnSave}
            loading={isLoading}
            disabled={isLoading}
          />
        </Box>
      </LayoutBox>
    </Box>
  );
});
