import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, Grid } from '../../ui';
import { uniqueId } from '../../ui/utils';
import { useApi } from '../../hooks';
import { LayoutBox } from '../../components';
import { usePolicyPageContext } from './context/PolicyPageContext';

export const NamedInsured = React.forwardRef((props, ref) => {
  const { policy, setGridReference, onSelectionChanged } = props;
  const { updateNamedInsured, downloadEndorsement, includeInPolicyForms } = useApi();
  const { namedInsureds, setNamedInsureds, endorsements = [] } = usePolicyPageContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [ordinal, setOrdinal] = useState();

  const columns = [
    {
      headerName: 'Name',
      field: 'name',
      editable: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
  ];

  useEffect(() => {
    if (!!endorsements.length) {
      const namedInsured = endorsements?.find(({ endorsementType }) => endorsementType === 'namedInsured');
      namedInsured?.ordinal && setOrdinal(namedInsured?.ordinal);
    }
  }, [endorsements]);

  useEffect(() => {
    setNamedInsureds(policy.namedInsureds?.map((insured) => ({ id: uniqueId(), name: insured })));
  }, [policy.namedInsureds]);

  const handleAddRow = () => {
    const newRow = { id: uniqueId(), name: '' };
    setNamedInsureds((prevData) => (Array.isArray(prevData) ? [...prevData, newRow] : [newRow]));
  };

  const handleUpdateNamedInsured = async () => {
    setIsLoading(true);
    try {
      await updateNamedInsured(
        policy.id,
        namedInsureds.filter(({ name }) => name && name.trim() !== '').map(({ name }) => name)
      );
      alert('Named Insured Updated Successfully.');
    } catch (error) {
      console.error('Error updating named insureds:', error);
      alert('An error occurred while updating named insureds. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadInsured = async () => {
    setIsDownloading(true);
    try {
      const response = await downloadEndorsement(policy.id, {
        endorsementType: 'namedInsured',
      });
      if (response) {
        const downloadUrl = response.data.value;
        window.open(downloadUrl, '_blank');
      }
    } catch (error) {
      console.error('Error saving payload:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleIncludeInPolicyForms = async () => {
    try {
      await includeInPolicyForms(policy.id, {
        endorsementType: 'namedInsured',
        ordinal,
      });
    } catch (error) {
      console.error('Error inserting named insureds in policy forms:', error);
      alert('An error occurred while inserting named insureds in policy forms. Please try again.');
    }
  };

  return (
    <Box>
      <div className="ag-theme-quartz" style={{ maxHeight: '600px', overflowY: 'auto' }}>
        <Grid
          data={namedInsureds}
          columns={columns}
          setGridReference={setGridReference}
          rowSelection="multiple"
          shouldShowPagination={false}
          columnWidth={150}
          enableRangeSelection={true}
          floatingFilter={false}
          onSelectionChanged={onSelectionChanged}
        />
      </div>
      <LayoutBox row justifyContent="flex-start" alignItems="center" flexWrap="wrap" gap={8}>
        <Box
          border="1px solid #919EAB52"
          borderRadius={8}
          margin="$2"
          style={{
            flex: '0 0 auto',
          }}
        >
          <Button color="#5F2994" variant="text" label="Add Insured" paddingX={16} paddingY={8} minHeight={40} onPress={handleAddRow} />
        </Box>

        <Box
          border="1px solid #919EAB52"
          borderRadius={8}
          margin="$2"
          style={{
            flex: '0 0 auto',
          }}
          minWidth="10%"
        >
          <Button
            onPress={handleUpdateNamedInsured}
            label={isLoading ? '' : 'Save'}
            paddingX={16}
            paddingY={8}
            minHeight={40}
            loading={isLoading}
            disabled={isLoading}
            variant="text"
            color="$primary"
          />
        </Box>

        <Box
          border="1px solid #919EAB52"
          borderRadius={8}
          margin="$2"
          style={{
            flex: '0 0 auto',
          }}
        >
          <Button
            onPress={handleDownloadInsured}
            label={isDownloading ? '' : 'Download'}
            paddingX={16}
            paddingY={8}
            minHeight={40}
            loading={isDownloading}
            disabled={isDownloading}
            color="$primary"
            variant="text"
          />
        </Box>

        <Box
          border="1px solid #919EAB52"
          borderRadius={8}
          margin="$2"
          style={{
            flex: '0 0 auto',
          }}
        ></Box>
        {!ordinal && (
          <Checkbox
            onChange={(event) => {
              if (event) {
                handleIncludeInPolicyForms(event);
              }
            }}
            label="Include in Policy Forms"
            color="$primary"
          />
        )}

        <Box
          border="1px solid #919EAB52"
          borderRadius={8}
          margin="$2"
          style={{
            flex: '0 0 auto',
          }}
        ></Box>
      </LayoutBox>
    </Box>
  );
});
